import { make } from "vuex-pathify";
import axios from '@/modules/common/axiosFacturacion';
import { util } from '@/plugins/util';

const state = () => ({
    tablaSucursals: [
        {
            id: 1,
            numero: 1,
            numero_sucursal: 1,
            nombre_comercial: "Sucursal 1",
            direccion: "Av Siempre Viva",
            telefono: "74859633",
            ciudad: "Santa Cruz",
            estado: 1,
        },
    ],
    loading: false,
});

const mutations = make.mutations(state);

const getters = {};

const actions = {
    reset({ commit }) {
        commit('SET_TABLA_SUCURSALS', []);
    },
    async getSucursales({ commit }) {
        try {
            commit('SET_LOADING', true)
            const { data } = await axios.get(`siat/operaciones/sucursal`);
            commit('SET_TABLA_SUCURSALS', data.data.sucursales)
        } catch (error) {
            util.showNotify(error.response.data.message, 'error');
        } finally {
            commit('SET_LOADING', false)
        }
    },
    async destroySucursal({ commit, dispatch }, id) {
        try {
            commit('SET_LOADING', true)
            const { data } = await axios.delete(`siat/operaciones/sucursal/` + id);
            util.showNotify(data.message, 'success');
            dispatch('getSucursales');
        } catch (error) {
            util.showNotify(error.response.data.message, 'error');
            return [];
        } finally {
            commit('SET_LOADING', false)
        }
    },
};

export default {
    namespaced: true,
    state, 
    mutations,
    getters,
    actions
}