const title = 'cliente';
const clienteRoutes = [
    {
        path: 'cliente',
        name: 'cliente-index',
        component: () => import('../views/index.vue'),
        meta: {
            title,
        },
    },
    {
        path: 'cliente/create',
        name: 'cliente-create',
        component: () => import('../views/create.vue'),
        meta: {
            title: `${title} | Nuevo`,
        },
    },
    {
        path: 'cliente/:id/edit',
        name: 'cliente-edit',
        component: () => import('../views/edit.vue'),
        meta: {
            title: `${title} | Editar`,
        },
    },
    {
        path: 'cliente/importar',
        name: 'cliente-importar',
        component: () => import('../views/import.vue'),
        meta: {
            title: `${title} | Importar`,
        },
    },
];
export default clienteRoutes;
