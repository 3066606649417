import { make } from "vuex-pathify";
import axios from '@/modules/common/axiosFacturacion';
import { util } from '@/plugins/util';

const state = () => ({
    loading: false,
});

const mutations = make.mutations(state);
const getters = {
};

const actions = {
    async syncSiat({ commit }) {
        try {
            commit('SET_LOADING', true)
            const response = await axios.get(`siat/syncactivate`);
            util.showNotify(response.data.message, 'success');
        } catch (error) {
            util.showNotify(error.response.data.message, 'error');
            return [];
        } finally {
            commit('SET_LOADING', false)
        }
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions,
}