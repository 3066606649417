import listarLeyendas from "./apis/listarLeyendas";
import listarMensajes from "./apis/listarMensajes";
import listarProductosServicios from "./apis/listaProductosServicios";
import listarEventosSignificativos from "./apis/listaEventosSignificativos";
import listarUnidadesMedidas from "./apis/listaUnidadesMedidas";
import listarMotivoAnulacion from "./apis/listaMotivoAnulacion";
import listarPaises from "./apis/listaPaises";
import listarTipoDocIdentidad from "./apis/listaTipoDocIdentidad";
import listarDocumentoSector from "./apis/listaDocumentoSector";
import listarTipoEmision from "./apis/listaTipoEmision";
import listarActividadSector from "./apis/listaActividadSector";
import listarTipoMoneda from "./apis/listaTipoMoneda";
import listarHabitaciones from "./apis/listaHabitaciones";
import listarTipoFactura from "./apis/listaTipoFactura";
import listarMetodoPago from "./apis/listaMetodoPago";
import listarFechaHora from "./apis/listaFechaHora";
import listarPuntosVenta from "./apis/listaPuntoVenta";
import listarActividadDocumentoSector from "./apis/listaActividadDocumentoSector";
import obtieneCuis from "./apis/obtieneCuis";
import parametroSiat from "./apis/parametroSiat";

import sucursal from "./sucursal";
import modalCreateEditSucursal from "./sucursal/createEditSucursal"

import puntosVenta from "./punto_venta/puntoVenta"
import evento from "./evento/evento";
import cufd from "./cufd/cufd"
import cui from "./cuis/cuis"


export default {
    namespaced: true,
    modules: {
        listarLeyendas,
        listarMensajes,
        listarProductosServicios,
        listarEventosSignificativos,
        listarUnidadesMedidas,
        listarMotivoAnulacion,
        listarPaises,
        listarTipoDocIdentidad,
        listarDocumentoSector,
        listarTipoEmision,
        listarActividadSector,
        listarTipoMoneda,
        listarHabitaciones,
        listarTipoFactura,
        listarMetodoPago,
        listarFechaHora,
        listarPuntosVenta,
        listarActividadDocumentoSector,
        obtieneCuis,
        sucursal,
        modalCreateEditSucursal,
        puntosVenta,
        evento,
        cufd,
        cui,
        parametroSiat,
    }
}