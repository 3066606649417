import { util } from '@/plugins/util';
import axios from '@/modules/common/axiosFacturacion'
import { make } from 'vuex-pathify';


const state = () => ({
    isLoading: true,
    tiempos: [],
    tiempoSelected: "",
    modalidades: [],
    modalidadSelected: null,
    tipos: [],
    tipoSelected: null,
    desplegar: false,
    monedas: [],
    monedaSelected: null,
    importeTotal: '',
    nombrePaquete: null,
    cantidadTimbre : "",
});

const mutations = make.mutations(state);

const getters = {};

const actions = {
    async storePaquete({ state, dispatch }) {
        try {
            if(await dispatch('validar')) {
                const TIEMPO_SELECTED = state.tiempoSelected ? state.tiempoSelected.id : '';
                const REQUEST = new FormData();
                REQUEST.append('nombre', state.nombrePaquete);
                REQUEST.append('importe_total', state.importeTotal);
                REQUEST.append('cantidad_timbre', state.cantidadTimbre);
                REQUEST.append('modalidad_paquete_id', state.modalidadSelected.id);
                REQUEST.append('moneda_id', state.monedaSelected.id);
                REQUEST.append('tipo_paquete_id', state.tipoSelected.id);
                REQUEST.append('tiempo_paquete_id', TIEMPO_SELECTED);
                const {data} = await axios.post('facturacion/paquete', REQUEST, {headers: {'Content-Type': 'multipart/form-data'}});
                util.showNotify(data.message, 'success');
                return true;
                
            }
        } catch (error) {
            const FALLO_VALIDACION = 422;
            if (error.response.status !== FALLO_VALIDACION) {
                util.showNotify(error.response.data.message, 'error');
            }else{
                Object.entries(error.response.data.data).forEach(([, mensajes]) => {
                    mensajes.forEach((texto) => util.showNotify(texto, 'error'));
                });
            }
            return false;
        }
    },
    async getDatoInicial({commit, state}) {
        try {
            const RESPONSE = await axios.get('/facturacion/paquete/nuevo');
            commit("SET_TIPOS", RESPONSE.data.data.tipoPaquete);
            commit("SET_TIEMPOS", RESPONSE.data.data.tiempoPaquete);
            commit("SET_MONEDAS", RESPONSE.data.data.moneda);
            commit("SET_MODALIDADES", RESPONSE.data.data.modalidadPaquete);
            state.isLoading = false;
            return true;
        } catch (error) {
            util.showNotify(error.response.data.message, 'error');
            return false;
        }
    },
    validar({state}) {
        let valido = true;
        if (!state.nombrePaquete) {
            util.showNotify('El campo nombre paquete es requerido', 'warn');
            valido = false;
        }
        if (!state.modalidadSelected) {
            util.showNotify('El campo modalidad es requerido', 'warn');
            valido = false;
        }
        if (!state.monedaSelected) {
            util.showNotify('El campo moneda es requerido', 'warn');
            valido = false;
        }
        if (!state.tipoSelected) {
            util.showNotify('El campo tipo es requerido', 'warn');
            valido = false;
        }
        return valido;
    },
    reset({ commit }) {
        commit("SET_NOMBRE_PAQUETE", null);
        commit("SET_IMPORTE_TOTAL", null);
        commit("SET_CANTIDAD_TIMBRE", null);
        commit("SET_TIEMPOS", []);
        commit("SET_TIEMPO_SELECTED", "");
        commit("SET_MODALIDADES", []);
        commit("SET_MODALIDAD_SELECTED", null);
        commit("SET_TIPOS", []);
        commit("SET_TIPO_SELECTED", null);
        commit("SET_DESPLEGAR", false);
        commit("SET_MONEDAS", []);
        commit("SET_MONEDA_SELECTED", null);
        commit("SET_IMPORTE_TOTAL", 0);
    },
};

export default {
    namespaced: true,
    state, 
    mutations,
    getters,
    actions,
}