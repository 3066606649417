import { make } from 'vuex-pathify';
import axios from '@/modules/common/axiosFacturacion';
import { util } from '@/plugins/util';

const state = () => ({
    registro: null,
    modalDestroyConfirmation: false,
});

const mutations = make.mutations(state);

const getters = {};

const actions = {
    reset({ commit }) {
        commit('SET_REGISTRO', null);
        commit('SET_MODAL_DESTROY_CONFIRMATION', false);
    },

    async eliminar({ dispatch }, item) {
            try {
            const { data } = await axios.delete(`facturacion/cliente/${item.id}`);
            util.showNotify(data.message, 'success');
            dispatch('reset');
            return true;
        } catch (error) {
            util.showNotify(error.response.data.message, 'error');
            return false;
        }
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions,
};