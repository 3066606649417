import { make } from "vuex-pathify";
const state = () => ({
        dominio: null,
        asuntoMail: null,
        mailRemitente: null,
        loadingCiudad: false,
        zonaHorarias: [],
        zonaHorariaSelected: null,
        ciudades: [],
        ciudadSelected: null,
        tipos: [],
        tipoSelected: null,
});
const mutations = make.mutations(state);

const getters = {};

const actions = {
    resetFormSitioWeb({commit}) {
        commit("SET_ZONA_HORARIAS", []);
        commit("SET_ZONA_HORARIA_SELECTED", null);
        commit("SET_CIUDADES", []);
        commit("SET_CIUDAD_SELECTED", null);
        commit("SET_LOADING_CIUDAD", false);
        commit("SET_DOMINIO", null);
        commit("SET_ASUNTO_MAIL");
        commit("SET_MAIL_REMITENTE", null);
        commit("SET_TIPOS", []);
        commit("SET_TIPO_SELECTED", null);
    },
};
export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions,
}