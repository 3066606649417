import usuarioIndex from "./apis/index";
import usuarioCreate from "./apis/store";
import usuarioDestroy from "./apis/destroy";
import usuarioUpdate from "./apis/update";

export default {
    namespaced: true,
    modules: {
        usuarioIndex,
        usuarioCreate,
        usuarioDestroy,
        usuarioUpdate,
    },
}