import { make } from "vuex-pathify";
import axios from '@/modules/common/axiosFacturacion';
import { util } from '@/plugins/util';

const state = () => ({
    tablaCufds: [],
    listaPuntoVenta: [],
    listaSucursales: [],    
    loading: false,
    showErrorSucursal: false,
    showErrorPuntoVenta: false,
    errorSucursal: "",
    errorPuntoVenta: "",
});

const mutations = make.mutations(state);

const getters = {};

const actions = {
    reset({ commit }) {
        commit('SET_TABLA_SUCURSALS', []);
    },
    async getCUFDS({commit}){
      commit("SET_LOADING", true)
      try {
        const { data } = await axios.get(`siat/connection/cufd`);
        if(data.error != 0)
          throw "Error en la obtencion de datos"
        commit("SET_TABLA_CUFDS", data.data.cufd)
      } catch (error) {
				if(error.response)
          util.showNotify(error.response.data.message, 'error');
				else
					util.showNotify(error.message, 'error');
      }finally{
				commit("SET_LOADING", false)
			}
    },
    async getCreate({commit}) {
      commit("SET_LOADING", true)
      try {
        const { data } = await axios.get(`siat/connection/cufd/create`);
        if(data.error != 0)
          throw "Error en la obtencion de datos"
        commit("SET_LISTA_PUNTO_VENTA", data.data.punto_ventas)
        commit("SET_LISTA_SUCURSALES", data.data.sucursales)
      } catch (error) {
				if(error.response)
          util.showNotify(error.response.data.message, 'error');
				else
					util.showNotify(error.message, 'error');
      }finally{
				commit("SET_LOADING", false)
			}
    },
    async storeCufd({commit, dispatch}, cufd) {
      try {
        if (await dispatch("validar", cufd)) {
          commit("SET_LOADING", true)
          commit("SET_SHOW_ERROR_SUCURSAL", false)
          commit("SET_SHOW_ERROR_PUNTO_VENTA", false)
          const params = {
            siat_punto_venta_id: cufd.siat_punto_venta_id.id,
            siat_sucursal_id: cufd.siat_sucursal_id.id,
          };
          const { data } = await axios.post(`siat/connection/cufd`, params);
          util.showNotify(data.message, 'success');
        }
      } catch (error) {
        let message = ""
        let localError = "" 
        if("siat_punto_venta_id" in error.response.data.data){
            commit("SET_SHOW_ERROR_SUCURSAL", true)
            localError = ""
            error.response.data.data.siat_punto_venta_id.forEach(e=> {
                message = message + " " + e
                localError = localError + " " + e
            })
            util.showNotify(localError, 'error');
            commit("SET_ERROR_SUCURSAL", localError)
        } else if("siat_sucursal_id" in error.response.data.data){
            commit("SET_SHOW_ERROR_PUNTO_VENTA", true)
            localError = ""
            error.response.data.data.siat_sucursal_id.forEach(e=> {
                message = message + " " + e
                localError = localError + " " + e
            })
            util.showNotify(localError, 'error');
            commit("SET_ERROR_PUNTO_VENTA", localError)
        } else {
          util.showNotify(error.response.data.message, 'error');
        }
			}finally{
				commit("SET_LOADING", false)
			}
    },
    validar(none, data) { 
      let valido = true;
      if (!data.siat_sucursal_id) {
          util.showNotify('La Sucursal es un campo requerido', 'error');
          valido = false;
      }
      if (!data.siat_punto_venta_id) {
          util.showNotify('El Punto de Venta es un campo requerido', 'error');
          valido = false;
      }

      return valido;
    }
};

export default {
    namespaced: true,
    state, 
    mutations,
    getters,
    actions
}