import { make } from 'vuex-pathify';
import axios from '@/modules/common/axiosFacturacion';
import { util } from '@/plugins/util';

const state = () => ({
    fields: [
        {
        key: 'editar', label: ' ', class: 'text-center', thStyle: { width: '3%'  },
        },
        {
        key: 'eliminar', label: ' ', class: 'text-center', thStyle: { width: '3%' },
        },
        {
        key: 'numero', label: 'N°', class: 'text-center', thStyle: { width: '3%' },
        },
        {
        key: 'nombre', label: 'Nombre', class: 'text-center', thStyle: { width: '14%' },
        },
        {
        key: 'modalidad_paquete', label: 'Modalidad', class: 'text-center', thStyle: { width: '10%' },
        },
        {
        key: 'tipo_paquete', label: 'Tipo', class: 'text-center', thStyle: { width: '10%' },
        },
        {
        key: 'importe_total', label: 'Importe', class: 'text-center', thStyle: { width: '10%' },
        },
        {
        key: 'cantidad_timbre', label: 'Cantidad', class: 'text-center', thStyle: { width: '10%' },
        },
        {
        key: 'tiempo_paquete', label: 'Tiempo', class: 'text-center', thStyle: { width: '10%' },
        },
    ],
    listaPaquetes: [],
    filter: '',
    porPagina: 100,
    paginaOpciones: [100, 200],
    estado: true,
    paginaActual: 1,
    totalFila: 0,
    isLoading: true,
})

const mutations = make.mutations(state);

const getters = {};

const actions = {
    listarPaquetes({ state, commit }){
        state.isLoading = true;
        const param = {
            page: state.paginaActual,
            per_page: state.porPagina,
            estado: state.estado,
            filter_key: state.filter,
        };
        axios.get('facturacion/paquete', {params: param})
        .then( response => {
            const DATA = response.data.data;
            commit("SET_LISTA_PAQUETES", DATA.data.paquetes);
            commit("SET_TOTAL_FILA", DATA.data.total);
        })
        .catch( error => {
            Object.entries(error.response.data.data).forEach(([, mensajes]) =>{
                mensajes.forEach((texto) => util.showNotify(texto, 'error'));
            });
        }).finally(() => {
            state.isLoading = false;
        })
    },
    reset({ commit }){
        commit("SET_FILTER", '');
        commit("SET_ESTADO", true);
    },
};

export default {
    namespaced: true,
    state, 
    mutations,
    getters,
    actions,
}