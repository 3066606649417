import { make } from 'vuex-pathify';
import axios from '@/modules/common/axiosFacturacion';
import { util } from '@/plugins/util';

const state = () => ({
    listaUsuarios: [],
    totalFila: 0,
    paginaActual: 1,
    porPagina: 50, //
    filter: '',
    estado: true,
    paginaOpciones: [50, 100],
});

const mutations = make.mutations(state);

const getters = {};

const actions = {
    listarUsuarios({ commit, state }) {
        const param = {
            page: state.porPagina,
            per_page: state.paginaActual,
            estado: state.estado,
            filter_key: state.filter,
        };
        axios.get('/facturacion/usuario', { params: param })
        .then( response => {
            commit("SET_LISTA_USUARIOS", response.data.data.data);
            commit("SET_TOTAL_FILA", response.data.data.total);
        })
        .catch( error => {
            console.log(error.response);
            Object.entries(error.response.data.data).forEach(([, mensajes]) => {
                mensajes.forEach((texto) => util.showNotify(texto, 'error'));
            });
        })
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions,
}