import configuracionIndex from "./apis/index";
import configuracionStore from "./apis/store";
import configuracionDestroy from "./apis/destroy";
import configuracionUpdate from "./apis/update";
import sitioWeb from "./sitioWeb";
import theModalListToken from "./theModalListToken";

export default {
    namespaced: true,
    modules: {
        configuracionIndex,
        configuracionStore,
        configuracionDestroy,
        configuracionUpdate,
        sitioWeb,
        theModalListToken
    }
}