import axios from '@/modules/common/axiosFacturacion';
const logoDefault = '@/assets/images/logo.png';

export default {
namespaced: true,
state: () => ({
    company: {},
    user: {},
    showModalCambioContrasena: false,
    empresas: [],
    empresaSelected: null,
    superAdmin: 1,
}),
mutations: {
    setCompany: (state, company) => { state.company = company; },
    setUser: (state, user) => { state.user = user; },
    setShowModalCambioContrasena: (state, showModalCambioContrasena) => { state.showModalCambioContrasena =  showModalCambioContrasena; },
    setEmpresas: (state, empresas) => { state.empresas = empresas },
    setEmpresaSelected: (state, empresaSelected) => { state.empresaSelected = empresaSelected},
},
actions: {
    async getData({ commit }) {
        const RESPONSE = await axios.get('/privilegio');
        const { data } = RESPONSE.data;
        commit('setUser', data.user);
        commit('setCompany', data.empresa);
        commit('setEmpresas', data.empresas);
        if (data.user.cambio_contrasena) {
            commit("setShowModalCambioContrasena", true);
        }
    },
    reset({commit}) {
        
        commit("setShowModalCambioContrasena", false);
    },
},
getters: {
    getPathLogo(state) {
        const logoPath = state.company.logo;
        if (logoPath) {
            return process.env.VUE_APP_BASE + logoPath;
        }
        return logoDefault;
    },
    getPathImgUser(state) {
        const logoPath = state.user.imagen;
        if (logoPath) {
            return `${process.env.VUE_APP_BASE}/${logoPath}`;
        }
        return logoDefault;
    },
}

}