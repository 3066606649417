import Vuex from "vuex";
import Vue from "vue";
import largeSidebar from "./modules/largeSidebar";
import compactSidebar from "./modules/compactSidebar";
import auth from './modules/auth';
import main from './modules/main';
import chat from "./modules/chat";
import config from "./modules/config";
import authData from "./modules/authData";
import invoice from "./modules/invoice";
import cart from "./modules/cart";
import verticalSidebar from "./modules/verticalSidebar";
import scrumboard from "./modules/scrumboard";
import usuario from "./modules/usuario";
import datoUsuaurio from "./modules/datoUsuario";
import paquete from "./modules/paquete";
import empresa from "./modules/empresa";
import configuracion from "./modules/configuracion";
import cliente from "./modules/cliente";
import facturacion from "./modules/facturacion";

import sincronizacion from "./modules/sincronizacion";
import datosEmpresa from "./modules/datosEmpresa";
// Load Vuex
Vue.use(Vuex);

// Create store
export default new Vuex.Store({
  modules: {
    largeSidebar,
    compactSidebar,
    chat,
    config,
    authData,
    invoice,
    cart,
    verticalSidebar,
    scrumboard,
    auth,
    main,
    usuario,
    paquete,
    empresa,
    configuracion,
    cliente,
    facturacion,
    sincronizacion,
    datosEmpresa,
    datoUsuaurio
  }
});
