import { make } from "vuex-pathify";
import axios from '@/modules/common/axiosFacturacion';
import { util } from '@/plugins/util';
// import { controllers } from "chart.js";

const state = () => ({
    tablaPuntoVenta: [],
    listaTipoPuntoVenta: [],
    listaTipoSucursales: [],    
    loading: false,
    loadingSync: false,
    showErrorNombre : false,
    showErrorSucursal: false,
    showErrorTipoPuntoVenta: false,
    errorNombre : "",
    errorSucursal: "",
    errorTipoPuntoVenta: "",
    showModal: false,
});

const mutations = make.mutations(state);

const getters = {};

const actions = {
    reset({ commit }) {
        commit('SET_TABLA_SUCURSALS', []);
    },
    async getPuntosVenta({commit}) {
        try {
            commit('SET_LOADING', true)
            const { data } = await axios.get(`siat/operaciones/puntoventas`);
            if(data.error != 0)
                throw "Error en la obtencion de datos"
            commit("SET_TABLA_PUNTO_VENTA", data.data.puntos_ventas)
        } catch (error) {
            util.showNotify(error.response.data.message, 'error');
        } finally {
            commit('SET_LOADING', false)
        }
    },
    async getPuntosVentaCreate({commit}) {
      try {
          const { data } = await axios.get(`siat/operaciones/puntoventas/create`);
          if(data.error != 0)
              throw "Error en la obtencion de datos"
          commit("SET_LISTA_TIPO_PUNTO_VENTA", data.data.tipo_puntos_ventas)
          commit("SET_LISTA_TIPO_SUCURSALES", data.data.tipo_sucursales)
      } catch (error) {
          util.showNotify(error.response.data.message, 'error');
      }
    },
    async syncPuntosVenta({commit}){
        try {
            commit("SET_LOADING_SYNC", true)
            const { data } = await axios.get(`siat/operaciones/puntoventas/sync`);
            util.showNotify(data.message, 'success');
            return true
        } catch (error) {
            util.showNotify(error.response.data.message, 'error');
        } finally {
            commit("SET_LOADING_SYNC", false)
        }
        return false
    },
    async storePuntoVenta({commit, dispatch}, puntoVenta) {
      
      try {
        if(await dispatch("validar", puntoVenta)) {
            commit("SET_LOADING", true)
            commit("SET_SHOW_ERROR_NOMBRE", false)
            commit("SET_SHOW_ERROR_SUCURSAL", false)
            commit("SET_SHOW_ERROR_TIPO_PUNTO_VENTA", false)
            const params = {
                nombre: puntoVenta.nombre,
                siat_tipo_punto_venta_id: puntoVenta.tipo_punto_venta.id,
                descripcion: puntoVenta.descripcion,
                siat_sucursal_id: puntoVenta.siat_sucursal.id,
            };
            const response = await axios.post(`siat/operaciones/puntoventas`, params);
            util.showNotify(response.data.message, 'success');
            commit('SET_SHOW_MODAL', false);
            dispatch('getPuntosVenta');
        }
      } catch (error) {
        let sw = true
        if("data" in error.response.data){
            let message = ""
            let localError = ""
            if("nombre" in error.response.data.data){
                commit("SET_SHOW_ERROR_NOMBRE", true)
                localError = ""
                error.response.data.data.nombre.forEach(e=> {
                    message = message + " " + e
                    localError = localError + " " + e
                })
                commit("SET_ERROR_NOMBRE", localError)
            }
            if("siat_tipo_punto_venta_id" in error.response.data.data){
                commit("SET_SHOW_ERROR_SUCURSAL", true)
                localError = ""
                error.response.data.data.siat_tipo_punto_venta_id.forEach(e=> {
                    message = message + " " + e
                    localError = localError + " " + e
                })
                commit("SET_ERROR_SUCURSAL", localError)
            }
            if("siat_sucursal_id" in error.response.data.data){
                commit("SET_SHOW_ERROR_TIPO_PUNTO_VENTA", true)
                localError = ""
                error.response.data.data.siat_sucursal_id.forEach(e=> {
                    message = message + " " + e
                    localError = localError + " " + e
                })
                commit("SET_ERROR_TIPO_PUNTO_VENTA", localError)
            }
            if(message != ""){
                sw= false
                util.showNotify(message, 'error');
            }
        }
        if(sw)
            util.showNotify(error.response.data.message, 'error');
      }finally{
        commit("SET_LOADING", false)
      }
    },
    async destroyPuntoVenta({ commit, dispatch }, id){
        try {
            commit("SET_LOADING", true)
            const { data } = await axios.delete(`siat/operaciones/puntoventas/` + id);
            dispatch('getPuntosVenta')
            util.showNotify(data.message, 'success');
        } catch (error) {
            util.showNotify(error.response.data.message, 'error');
        } finally {
            commit("SET_LOADING", false);
        }
        return false
    },
    async validar(none, data) {
        let valido = true;
        
        if (!data.nombre) {
            util.showNotify('El campo nombre comercial es requerido', 'warn');
            valido = false;
        }
        if (!data.tipo_punto_venta) {
            util.showNotify('El campo Punto de Venta es requerido', 'warn');
            valido = false;
        }
        if (!data.descripcion) {
            util.showNotify('El campo Descripcion es requerido', 'warn');
            valido = false;
        }
        if (!data.siat_sucursal) {
            util.showNotify('El campo Sucursal es requerido', 'warn');
            valido = false;
        }
        return valido;
    },
};

export default {
    namespaced: true,
    state, 
    mutations,
    getters,
    actions
}