const title = "Facturacion";
const facturacionRoutes = [
    {
        path: '/facturacion',
        name: 'facturacion-index',
        component: () => import('../views/index.vue'),
        meta: {
            title,
        },
    },
    {
        path: '/facturacion/create',
        name: 'facturacion-create',
        component: () => import('../views/create.vue'),
        meta: {
            title: `${title} | Nuevo`,
        },
    },
    {
        path: '/facturacion/:id/edit',
        name: 'facturacion-edit',
        component: () => import('../views/edit.vue'),
        meta: {
            title: `${title} | Editar`,
        },
    },
];
export default facturacionRoutes;