import { make } from 'vuex-pathify';
import axios from '@/modules/common/axiosFacturacion';
import { util } from '@/plugins/util';
import moment from "moment";

const state = () => ({
    nombreComercial: null,
    empresa: {
        texto_numero_contribuyente:"NIT",
        texto_razon_social:"Razon Social"
    },
    isLoading: true,
    loadingCiudad: false,
    codigoCliente: null,
    fechaTrabajo: new Date(moment()),
    tipoClienteSelected: null,
    tipoClientes: [],
    celularTelefono: null,
    tag: {},
    tags : [],
    direccion: null,
    paisSelected: null,
    paises: [],
    ciudadSelected: null,
    ciudades: [],
    limiteCredito: null,
    origenCliente: null,
    origenesClientes: [],
    descripcion: null,
    razonSocial: null,
    ciNit: null,
    numeroRegistro: null,
});

const mutations = make.mutations(state);

const getters = {};

const actions = {
    
    async getDatoInicial({ commit }, id) {
        try {
            const RESPONSE = await axios.get(`/facturacion/cliente/${id}/edit`);
            console.log(RESPONSE.data.data);
           
            const PAISES =RESPONSE.data.data.paises;
            const PAIS_ID = RESPONSE.data.data.cliente.pais_id;

            const CIUDADES = RESPONSE.data.data.ciudades;
            const CIUDAD_ID = RESPONSE.data.data.cliente.ciudad_id;
          
            const CIUDAD_SELECTED = CIUDADES.find((ele) => (ele.id === CIUDAD_ID));

            const ORIGENESCLIENTE = RESPONSE.data.data.origenesClientes;
            const ORIGENESCLIENTE_ID = RESPONSE.data.data.cliente.origen_cliente_id;

            const PAIS_SELECTED = PAISES.find((ele) => (ele.id === PAIS_ID));
            const ORIGENES_SELECTED= ORIGENESCLIENTE.find((ele) => (ele.id === ORIGENESCLIENTE_ID)); 

          const DATE = new Date(moment(RESPONSE.data.data.cliente.fecha_trabajo));
          
            commit("SET_NUMERO_REGISTRO", RESPONSE.data.data.cliente.numero);
            commit("SET_NOMBRE_COMERCIAL", RESPONSE.data.data.cliente.nombre_comercial);
            commit("SET_CODIGO_CLIENTE", RESPONSE.data.data.cliente.codigo_cliente);
            commit("SET_FECHA_TRABAJO", DATE);
            commit("SET_CELULAR_TELEFONO", RESPONSE.data.data.cliente.telefono);
            commit("SET_TAG", RESPONSE.data.data.cliente.array.emails);
            commit("SET_DIRECCION", RESPONSE.data.data.cliente.direccion);
            commit("SET_PAISES", PAISES);
            commit("SET_PAIS_SELECTED", PAIS_SELECTED);
            commit("SET_CIUDADES", CIUDADES);
            commit("SET_CIUDAD_SELECTED", CIUDAD_SELECTED);
            commit("SET_LIMITE_CREDITO", RESPONSE.data.data.cliente.limite_credito);
            commit("SET_ORIGENES_CLIENTES", ORIGENESCLIENTE);
            commit("SET_ORIGEN_CLIENTE", ORIGENES_SELECTED);
            commit("SET_DESCRIPCION", RESPONSE.data.data.cliente.descripcion);
            commit("SET_RAZON_SOCIAL", RESPONSE.data.data.cliente.razon_social);
            commit("SET_CI_NIT", RESPONSE.data.data.cliente.nit);
            return true;
        } catch (error) {
            Object.entries(error.response.data.data).forEach(([, mensajes]) => {
                mensajes.forEach((texto) => util.showNotify(texto, 'error'));
            });
            return false;
        }
    },
   
    async getCiudad({commit}, item) {
        try {
            commit("SET_LOADING_CIUDAD", true);
           const RESPONSE =  await axios.get(`/api/facturacion/cliente/ciudades/${item.id}`);
           commit("SET_CIUDADES", RESPONSE.data.data.ciudades);
           console.log(RESPONSE.data.data.ciudades);
         return true;
        } catch (error) {
            Object.entries(error.response.data.data).forEach(([, mensajes]) => {
                mensajes.forEach((texto) => util.showNotify(texto, 'error'));
            });
            return false;
        }finally {
            commit("SET_LOADING_CIUDAD", false);
        }
    },
    async getCliente({ commit }) {
        try {
            const RESPONSE = await axios.get('/facturacion/cliente/nuevo');
            commit("SET_ORIGENES_CLIENTES", RESPONSE.data.data.origenesClientes);
            
            return true;
        } catch (error) {
            Object.entries(error.response.data.data).forEach(([, mensajes]) => {
            mensajes.forEach((texto) => util.showNotify(texto, 'texto'));
            });
            return false;
        } 
    },
    async updateUser({ state, dispatch }, id) {
        try {
            if (await dispatch('validar')) {
                var REQUEST = {
                    nombre_comercial: state.nombreComercial,
                    razon_social: state.razonSocial,
                    nit:state.ciNit,
                    codigo_cliente: state.codigoCliente,
                    fecha_trabajo: moment(state.fecha_trabajo).format('YYYY-MM-DD'),
                    telefono: state.celularTelefono,
                    emails: state.tags,
                    direccion: state.direccion,
                    pais_id: state.paisSelected.id,
                    ciudad_id: state.ciudadSelected.id,
                    limite_credito: state.limiteCredito,
                    descripcion: state.descripcion,
                    origen_cliente_id: state.origenCliente.id ? state.origenCliente.id : null,
                    };
                const {data} = await axios.post(`facturacion/cliente/${id}`, REQUEST);
                util.showNotify(data.message, 'success');
                return true;
            }
        } catch (error) {
            const FALLO_VALIDACION = 422;
            if (error.response.status !== FALLO_VALIDACION) {
                util.showNotify(error.response.data.message, 'error');
            } else {
                Object.entries(error.response.data.data).forEach(([, mensajes]) => {
                    mensajes.forEach((texto) => util.showNotify(texto, 'error'));
                });
            }
            return false;
        }
    },
   validar({ state }) {
        let valido = true;
        if (!state.nombreComercial) {
            util.showNotify('El campo nombre comercial es requerido', 'warn');
            valido = false;
        }
        return valido;
    },
    reset({ commit }) {
        commit("SET_IS_LOADING", true);
        commit("SET_NOMBRE_COMERCIAL", null);
        commit("SET_CODIGO_CLIENTE", null);
        commit("SET_FECHA_TRABAJO", moment(new Date()).format('YYYY-MM-D'));
        commit("SET_CELULAR_TELEFONO", null);
        commit("SET_TAG", []);
        commit("SET_TAGS", null);
        commit("SET_DIRECCION", null);
        commit("SET_PAIS_SELECTED", null);
        commit("SET_PAISES", []);
        commit("SET_CIUDADES", []);
        commit("SET_CIUDAD_SELECTED", null)
        commit("SET_LIMITE_CREDITO", null);
        commit("SET_ORIGEN_CLIENTE", null);
        commit("SET_ORIGENES_CLIENTES", []);
        commit("SET_DESCRIPCION", null);
        commit("SET_RAZON_SOCIAL", null);
        commit("SET_CI_NIT", null);
        commit("SET_LOADING_CIUDAD", false);
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions,
}