import { make } from "vuex-pathify";
const state = () => ({
    lisTokens: [],
});

const mutations = make.mutations(state);

const getters = {};

const actions = {};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions,
}