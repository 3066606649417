import { make } from "vuex-pathify";
import axios from '@/modules/common/axiosFacturacion';
import { util } from '@/plugins/util';

const state = () => ({
    isLoading: true,
    nombreSistema: null,
    codSistemaProveedor: null,
    nit: null,
    paises: [],
    paisSelected: null,
    nombreProveedor: null,
    logo: null,
    urlImage: null,
    numeroRegistro: null,
});

const mutations = make.mutations(state);

const getters = {};

const actions = {
    async getDatoInical({ commit }, id) {
        try {
            const RESPONSE = await axios.get(`/facturacion/configuracion/${id}/edit`);

            const PAISES = RESPONSE.data.data.pais;
            const PAIS_ID = RESPONSE.data.data.configuracion.pais_id;
            const PAIS_SELECTED = PAISES.find((ele) => (
                ele.id === PAIS_ID
            ));
            commit("SET_PAISES", PAISES);
            commit("SET_PAIS_SELECTED", PAIS_SELECTED);
            commit("SET_NUMERO_REGISTRO", RESPONSE.data.data.configuracion.numero);
            commit("SET_NOMBRE_PROVEEDOR", RESPONSE.data.data.configuracion.proveedor_software);
            commit("SET_NOMBRE_SISTEMA", RESPONSE.data.data.configuracion.nombre_sistema);
            commit("SET_NIT", RESPONSE.data.data.configuracion.nit);
            commit("SET_URL_IMAGE", RESPONSE.data.data.configuracion.logo);
            commit("SET_COD_SISTEMA_PROVEEDOR", RESPONSE.data.data.configuracion.codigo_sis_proveedor);

            commit("configuracion/sitioWeb/SET_DOMINIO",
            RESPONSE.data.data.configuracion.nombre_dominio, { root: true });
            commit("configuracion/sitioWeb/SET_ASUNTO_MAIL",
            RESPONSE.data.data.configuracion.titulo_asunto_mail, { root: true });
            commit("configuracion/sitioWeb/SET_MAIL_REMITENTE",
            RESPONSE.data.data.configuracion.mail_remitente, { root: true });
            const TIPOS = RESPONSE.data.data.tipo_configuracions;
            const TIPO_SELECTED_ID = RESPONSE.data.data.configuracion.tipo_configuracion_web_id;
            const TIPO_SELECTED = TIPOS.find((ele) => (
                ele.id === TIPO_SELECTED_ID 
            ));

            const ZONA_HORARIAS = RESPONSE.data.data.zona_horarias;
            const ZONA_HORARIA_ID = RESPONSE.data.data.configuracion.zona_horaria_id;
            const ZONA_HORARIA_SELECTED = ZONA_HORARIAS.find((ele) => (
                ele.id === ZONA_HORARIA_ID
            ));

            const CIUDADES = RESPONSE.data.data.ciudades;
            const CIUDAD_ID = RESPONSE.data.data.configuracion.ciudad_id;
            const CIUDAD_SELECTED = CIUDADES.find((ele) => (
                ele.id === CIUDAD_ID
            ));
            commit("configuracion/sitioWeb/SET_TIPOS", TIPOS, { root: true });
            commit("configuracion/sitioWeb/SET_TIPO_SELECTED", TIPO_SELECTED, { root: true });
            commit("configuracion/sitioWeb/SET_ZONA_HORARIAS", RESPONSE.data.data.zona_horarias, { root: true });
            commit("configuracion/sitioWeb/SET_ZONA_HORARIA_SELECTED", ZONA_HORARIA_SELECTED, { root: true });
            commit("configuracion/sitioWeb/SET_CIUDADES", CIUDADES, { root: true });
            commit("configuracion/sitioWeb/SET_CIUDAD_SELECTED", CIUDAD_SELECTED, { root: true });

            return true;
        } catch (error) {
            Object.entries(error.response.data.data).forEach(([, mensajes]) => {
                mensajes.forEach((texto) => util.showNotify(texto, 'error'));
            });
            return false;
        }
    },
    async getCiudad({commit}, item) {
        try {
            commit("configuracion/sitioWeb/SET_CIUDAD_SELECTED", null, { root: true });
            commit("configuracion/sitioWeb/SET_CIUDADES", [], { root: true });
            commit("configuracion/sitioWeb/SET_LOADING_CIUDAD", true, { root: true });
            const RESPONSE = await axios.get(`/facturacion/get_ciudad/${item.id}`);
            commit('configuracion/sitioWeb/SET_CIUDADES', RESPONSE.data.data.ciudades, { root: true });
            return true;
        } catch (error) {
            Object.entries(error.response.data.data).forEach(([, mensajes]) => {
                mensajes.forEach((texto) => util.showNotify(texto, 'error'));
            });
            return false;
        } finally {
            commit("configuracion/sitioWeb/SET_LOADING_CIUDAD", false, { root: true });
        }
    },
    async UpdateConfiguracion({ state, dispatch, rootState }, id) {
        try {
            if (await dispatch('validar')) {
                const REQUEST = new FormData();
                const LOGO = (!state.logo) ? '' : state.logo;
                const DOMINIO = rootState.configuracion.sitioWeb.dominio
                ? rootState.configuracion.sitioWeb.dominio : '';
                const ASUNTO_MAIL = rootState.configuracion.sitioWeb.asuntoMail
                ? rootState.configuracion.sitioWeb.asuntoMail : '';
                const MAIL_REMITENTE = rootState.configuracion.sitioWeb.mailRemitente
                ? rootState.configuracion.sitioWeb.mailRemitente : ''; 
                const CIUDAD_ID = rootState.configuracion.sitioWeb.ciudadSelected.id;
                const TIPO_ID = rootState.configuracion.sitioWeb.tipoSelected.id;
                const ZONA_HORAIA_ID = rootState.configuracion.sitioWeb.zonaHorariaSelected.id;

                REQUEST.append('nombre_proveedor', state.nombreProveedor);
                REQUEST.append('nombre_sistema', state.nombreSistema);
                REQUEST.append('codigo_sistema', state.codSistemaProveedor);
                REQUEST.append('nit', state.nit);
                REQUEST.append('logo', LOGO);
                REQUEST.append('nombre_dominio', DOMINIO);
                REQUEST.append('titulo_asunto_mail', ASUNTO_MAIL);
                REQUEST.append('mail_remitente', MAIL_REMITENTE);
                REQUEST.append('pais_id', state.paisSelected.id);
                REQUEST.append('ciudad_id', CIUDAD_ID);
                REQUEST.append('tipo_configuracion_id', TIPO_ID);
                REQUEST.append('zona_horaria_id', ZONA_HORAIA_ID);
                const {data} = await axios.post(`facturacion/configuracion/${id}/update`, REQUEST);
                util.showNotify(data.message, 'success');
                return true;
            }
        } catch (error) {
            const FALLO_VALIDACION = 422;
            if (error.response.status !== FALLO_VALIDACION) {
                util.showNotify(error.response.data.message, 'error');
            } else {
                Object.entries(error.response.data.data).forEach(([, mensajes]) => {
                    mensajes.forEach((texto) => util.showNotify(texto, 'error'));
                });
            }
            return false;
        }
    },
    validar({ state, rootState }) {
        const CIUDAD = rootState.configuracion.sitioWeb.ciudadSelected;
        const TIPO_SELECTED = rootState.configuracion.sitioWeb.tipoSelected;
        const ZONA_HORARIA = rootState.configuracion.sitioWeb.zonaHorariaSelected;
        let valido = true;
        if (!state.paisSelected) {
            util.showNotify('El campo pais es requerido', 'warn');
            valido = false;
        }
        if (!CIUDAD) {
            util.showNotify('El campo ciudad es requerido', 'warn');
            valido = false;
        }
        if (!TIPO_SELECTED) {
            util.showNotify('El campo tipo es requerido', 'warn');
            valido = false;
        }
        if (!state.nit) {
            util.showNotify('El campo nit es requerido', 'warn');
            valido = false;
        }
        if (!ZONA_HORARIA) {
            util.showNotify('El campo zona horaria es requerido', 'warn');
            valido = false;
        }
        if (!state.nombreSistema) {
            util.showNotify('El campo nombre de Sistema es requerido', 'warn');
            valido = false;
        }
        if (!state.codSistemaProveedor) {
            util.showNotify('El campo codigo sistema es requerido', 'warn');
            valido = false;
        }
        return valido;
    },
    reset({ commit, dispatch }) {
        commit("SET_IS_LOADING", true);
        commit("SET_NOMBRE_SISTEMA", null);
        commit("SET_COD_SISTEMA_PROVEEDOR", null);
        commit("SET_NIT", null);
        commit("SET_PAISES", []);
        commit("SET_PAIS_SELECTED", null);
        commit("SET_NOMBRE_PROVEEDOR", null);
        commit("SET_LOGO", null);
        commit("SET_URL_IMAGE", null);
        commit("SET_NUMERO_REGISTRO", null);
        dispatch('configuracion/sitioWeb/resetFormSitioWeb', null, { root: true });
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions,
}