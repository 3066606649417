const title = 'Gemgloo-Factura';
const dashboardRoutes = [
    {
        path: 'dashboard',
        name: 'dashboard-index',
        component: () => import('../views/index.vue'),
        meta: {
            title,
        },
    },
];
export default dashboardRoutes;