import { make } from "vuex-pathify";
import axios from '@/modules/common/axiosFacturacion';
import { util } from '@/plugins/util';

const state = () => ({
    isLoading: false,
    downloadUrl: null
});

const mutations = make.mutations(state);

const getters = {};

const actions = {
    async getPdf({ commit }, id) {
        try {
            commit("SET_IS_LOADING", true);
            const response = await axios.get('siat/compraventa/ventas/pdf/' + id, {responseType: 'blob'});
            const blob = new Blob([response.data], { type: 'application/pdf' });
            commit("SET_DOWNLOAD_URL", URL.createObjectURL(blob));
        } catch (error) {
            util.showNotify('Hubo un problema al obtener el PDF', 'error');
        } finally {
            commit("SET_IS_LOADING", false);
        }
    },
    async getPdfNormal({ state, dispatch }, id) {
        await dispatch("getPdf", id);
        window.open(state.downloadUrl, 'Factura');
    },
    async getPdfRapida({ dispatch }, id) {
        await dispatch("getPdf", id);
        window.frames.printer.focus();
        setTimeout(() => {
            window.frames.printer.print();
        }, 500);
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions,
}