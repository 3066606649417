const title = 'Usuarios';
const usuarioRoutes = [
    {
        path: 'sistema/usuario',
        name: 'usuario-configuracion-index',
        component: () => import('../views/index.vue'),
        meta: {
            title,
        },
    },
    {
        path: 'sistema/usuario/create',
        name: 'usuario-configuracion-create',
        component: () => import('../views/create.vue'),
        meta: {
        title: `${title} | Nuevo`,
        },
    },
    {
        path: 'sistema/usuario/:id/edit',
        name: 'usuario-configuracion-edit',
        component: () => import('../views/edit.vue'),
        meta: {
        title: `${title} | Editar`,
        },
    },
];
export default usuarioRoutes;