const title = 'Datos de Empresa';
const datoEmpresaRoute = [
    {
        path: 'datos_empresa',
        name: 'datos_empresa-index',
        component:() => import('../views/edit.vue'),
        meta: {
            title,
        },
    }
];
export default datoEmpresaRoute