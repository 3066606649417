const title = 'Sincronizar Siat';

const sincronizarSiatRoute = [
    {
        path: 'sincronizacion_siat',
        name: 'sincronizacion_siat-index',
        component:() => import('../views/index.vue'),
        meta: {
            title,
        },
    }
];

export default sincronizarSiatRoute;