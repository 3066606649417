import clienteIndex from './apis/index';
import formularioCliente from './apis/formularioCliente';
import clienteUpdate from './apis/update';
import clienteDestroy from './apis/destroy';

export default {
    namespaced: true,
    modules: {
        clienteIndex,
        formularioCliente,
        clienteUpdate,
        clienteDestroy,

    },
};