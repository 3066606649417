const title = 'Paquetes';
const paqueteRoutes = [
    {
        path: 'paquete',
        name: 'paquete-index',
        component: () => import('../views/index.vue'),
        meta: {
            title
        },
    },
    {
        path: 'paquete/create',
        name: 'paquete-create',
        component: () => import('../views/create.vue'),
        meta: {
            title: `${title} | Nuevo`,
        },
    },
    {
        path: 'paquete/:id/edit',
        name: 'paquete-edit',
        component: () => import('../views/edit.vue'),
        meta: {
            title: `${title} | EDITAR`,
        },
    },
];
export default paqueteRoutes;