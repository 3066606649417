import { make } from "vuex-pathify";
import axios from '@/modules/common/axiosFacturacion';
import moment from "moment";
import { util } from '@/plugins/util';

const state = () => ({
    nombreComercial: null,
    razonSocial: null,
    nit: null,
    fecha: null, 
    fechaFinal: null,
    origenUrl: null,
    ciudadSelected: null,
    paqueteSelected: null,
    facturacionSelected: null,
    documentoSelected: [],
    importe: 0,
    monedaSelected: null,
    cantidadTimbre: null,
    confirmarTimbre: null,
    tiempoSelected: null,
    cantidadActual: null,
    fechaVencimiento: null,
    codigoGenerado: null,
    ambienteSelected: null,
    proveedorSoftwareSelected: null,

    paquetes: [],
    facturacionTipos: [],
    tipoDocumentos: [],
    monedas: [],
    tiempos: [],
    proveedorSoftwares: [],
    ambientes: [],
    numeroRegistro: null,
    tokenDelegado: null,
    vencimientoToken: null,
    lisTokens: [],
    empresaIntegracionId: null,
    entornoSelected: null,
    entornos: [],
    razonSocialNumero: null,
    medicoNumero: null,
});

const mutations = make.mutations(state);

const getters = {};

const actions = {
    async getDatosInicial({commit}, id) {
        try {
            const RESPONSE = await axios.get(`/facturacion/empresa/${id}/edit`);
            commit('SET_MONEDAS', RESPONSE.data.data.monedas);
            commit('SET_PROVEEDOR_SOFTWARES', RESPONSE.data.data.proovedor_softwares);
            commit('SET_FACTURACION_TIPOS', RESPONSE.data.data.facturacions);
            commit('SET_PAQUETES', RESPONSE.data.data.paquetes);
            commit('SET_TIPO_DOCUMENTOS', RESPONSE.data.data.siat_tipo_documento_sectors);
            commit('SET_AMBIENTES', RESPONSE.data.data.ambientes);
            commit('SET_TIEMPOS', RESPONSE.data.data.tiempos);
            commit('SET_NOMBRE_COMERCIAL', RESPONSE.data.data.empresa.nombre_comercial);
            commit('SET_RAZON_SOCIAL', RESPONSE.data.data.empresa.razon_social);
            commit('SET_NIT', RESPONSE.data.data.empresa.nit);
            commit('SET_FECHA', new Date(moment(RESPONSE.data.data.empresa.fecha_inicio)));
            commit('SET_FECHA_FINAL', new Date(moment(RESPONSE.data.data.empresa.fecha_final)));
            commit('SET_ORIGEN_URL', RESPONSE.data.data.empresa.origen);
            commit('SET_CIUDAD_SELECTED', RESPONSE.data.data.empresa.ciudad);
            commit('SET_IMPORTE',  parseFloat(RESPONSE.data.data.empresa.importe));
            commit('SET_CANTIDAD_TIMBRE', RESPONSE.data.data.empresa.cantidad_timbre);
            commit('SET_CONFIRMAR_TIMBRE', RESPONSE.data.data.empresa.confirmar_timbre);
            commit('SET_CANTIDAD_ACTUAL', RESPONSE.data.data.empresa.cantidad_actual);
            commit('SET_NUMERO_REGISTRO', RESPONSE.data.data.empresa.numero);
            commit('SET_DOCUMENTO_SELECTED', RESPONSE.data.data.documento_sector_empresas);

            commit("SET_TOKEN_DELEGADO", RESPONSE.data.data.empresa.token_delegado);
            commit("SET_VENCIMIENTO_TOKEN", RESPONSE.data.data.empresa.fecha_vencimiento_token);
            commit("SET_EMPRESA_INTEGRACION_ID", RESPONSE.data.data.empresa.empresa_integracion_id);
            commit("SET_ENTORNOS", RESPONSE.data.data.entornos);
            commit("SET_MEDICO_NUMERO", RESPONSE.data.data.empresa.medico_numero);
            commit("SET_RAZON_SOCIAL_NUMERO", RESPONSE.data.data.empresa.razon_social_medico);
            const LIST_TOKENS = RESPONSE.data.data.list_tokens;
            LIST_TOKENS.forEach(element => {
                element.key = element.id
            });
            commit("configuracion/theModalListToken/SET_LIS_TOKENS",  LIST_TOKENS, {root: true});
            const PAQUETES =  RESPONSE.data.data.paquetes;
            const PAQUETE_ID = RESPONSE.data.data.empresa.paquete_id;
            const PAQUETE_SELECTED = PAQUETES.find((ele) => (
                ele.id === PAQUETE_ID
            ));
            commit('SET_PAQUETE_SELECTED', PAQUETE_SELECTED);

            const FACTURACIONS = RESPONSE.data.data.facturacions;
            const FACTURACIONS_ID = RESPONSE.data.data.empresa.siat_modalidad_id;
            const FACTURACION_SELECTED = FACTURACIONS.find((ele) => (
                ele.id === FACTURACIONS_ID
            ));
            commit('SET_FACTURACION_SELECTED', FACTURACION_SELECTED);
            
            const MONEDAS = RESPONSE.data.data.monedas;
            const MONEDA_ID = RESPONSE.data.data.empresa.moneda_id;
            const MONEDA_SELECTED = MONEDAS.find((ele) => (
                ele.id === MONEDA_ID
            ));
            commit('SET_MONEDA_SELECTED', MONEDA_SELECTED);

            const TIEMPOS = RESPONSE.data.data.tiempos;
            const TIEMPO_ID = RESPONSE.data.data.empresa.tiempo_id;
            const TIEMPO_SELECTED = TIEMPOS.find((ele) => (
                ele.id === TIEMPO_ID
            ));
            commit('SET_TIEMPO_SELECTED', TIEMPO_SELECTED);
            
            const AMBIENTES = RESPONSE.data.data.ambientes;
            const AMBIENTE_ID = RESPONSE.data.data.empresa.siat_ambiente_id;
            const AMBIENTE_SELECTED = AMBIENTES.find((ele) => (
                ele.id === AMBIENTE_ID
            ));
            commit('SET_AMBIENTE_SELECTED', AMBIENTE_SELECTED);

            const PROVEEDORS_SOFTWARES = RESPONSE.data.data.proovedor_softwares;
            const PROVEEDOR_ID = RESPONSE.data.data.empresa.configuracion_id;
            const PROVEEDOR_SELECTED = PROVEEDORS_SOFTWARES.find((ele) => (
                ele.id === PROVEEDOR_ID
            ));
            commit('SET_PROVEEDOR_SOFTWARE_SELECTED', PROVEEDOR_SELECTED);

            const ENTORNOS = RESPONSE.data.data.entornos;
            const ENTORNO_ID = RESPONSE.data.data.empresa.tipo_entorno_id;
            const ENTORNO_SELECTED = ENTORNOS.find((ele) => (
                ele.id === ENTORNO_ID
            ));
            commit("SET_ENTORNO_SELECTED", ENTORNO_SELECTED);
        } catch (error) {
            util.showNotify(error.response.data.message, 'error');
            return false;
        }
    },
    validar({state}) {
        let valido = true;
        if (!state.paqueteSelected) {
            util.showNotify('El campo paquete es requerido', 'warn');
            valido = false;
        }
        if (!state.proveedorSoftwareSelected) {
            util.showNotify('El campo proveedor de software es requerido', 'warn');
            valido = false;
        }
        if (!state.facturacionSelected) {
            util.showNotify('El campo facturacion es requerido', 'warn');
            valido = false;
        }
        if (!state.ambienteSelected) {
            util.showNotify('El campo ambiente es requerido', 'warn');
            valido = false;
        }
        if (!state.tiempoSelected) {
            util.showNotify('El campo tiempos es requerido', 'warn');
            valido = false;
        }
        if (!state.monedaSelected) {
            util.showNotify('El campo moneda es requerido', 'warn');
            valido = false;
        }
        if (!state.fecha) {
            util.showNotify('El campo fecha inicio es requerido', 'warn');
            valido = false;
        }
        if (!state.fechaFinal) {
            util.showNotify('El campo fecha final es requerido', 'warn');
            valido = false;
        }
        if (state.fecha > state.fechaFinal) {
            util.showNotify('El campo fecha final debe ser mayor que la fecha inicio', 'warn');
            valido = false;
        }
        if (!state.entornoSelected) {
            util.showNotify('Debe selecionar un tipo de entorno', 'warn');
            valido = false;
        }
        
        return valido;
    },
    async updateEmpresa({ state, dispatch, rootState }, id) {
        try {
            if (await dispatch('validar')) {
                let  lisTokens = rootState.configuracion.theModalListToken.lisTokens;
                const REQUEST = {
                    nombre_comercial: state.nombreComercial,
                    razon_social: state.razonSocial,
                    origen: state.origenUrl,
                    nit: state.nit,
                    ciudad: state.ciudadSelected,
                    proveedor_sofware_id: state.proveedorSoftwareSelected.id,
                    paquete_id: state.paqueteSelected.id,
                    facturacion_id: state.facturacionSelected.id,
                    ambiente_id: state.ambienteSelected.id,
                    importe: state.importe,
                    moneda_id: state.monedaSelected.id,
                    cantidad_timbre: state.cantidadTimbre,
                    confirmar_timbre: state.confirmarTimbre,
                    tiempo_id: state.tiempoSelected.id,
                    cantidad_actual: state.cantidadActual,
                    fecha_inicio: moment(state.fecha).format('YYYY-MM-DD'),
                    fecha_final: moment(state.fechaFinal).format('YYYY-MM-DD'),
                    documento_sectors: state.documentoSelected,
                    token_delegado: state.tokenDelegado,
                    fecha_vencimiento_token: (state.vencimientoToken) ? moment(state.vencimientoToken, 'DD/MM/YYYY').format("YYYY-MM-DD") : '',
                    list_tokens: lisTokens,
                    tipo_entorno: state.entornoSelected.id,
                };
                const {data} = await axios.post(`facturacion/empresa/${id}/update`, REQUEST);
                util.showNotify(data.message, 'success');
                return true;
            }
        } catch (error) {
            const FALLO_VALIDACION = 422;
            if (error.response.status !== FALLO_VALIDACION) {
                util.showNotify(error.response.data.message, 'error');
            } else {
                Object.entries(error.response.data.data).forEach(([, mensajes]) => {
                    mensajes.forEach((texto) => util.showNotify(texto, 'error'));
                });
            }
            return false;
        }
    },
    reset({ commit }) {
        commit('SET_MONEDAS', []);
            commit('SET_PROVEEDOR_SOFTWARES', []);
            commit('SET_FACTURACION_TIPOS', []);
            commit('SET_PAQUETES', []);
            commit('SET_TIPO_DOCUMENTOS', []);
            commit('SET_AMBIENTES', []);
            commit('SET_TIEMPOS', []);
            commit('SET_NOMBRE_COMERCIAL', null);
            commit('SET_RAZON_SOCIAL', null);
            commit('SET_NIT', null);
            commit('SET_FECHA', new Date(moment()));
            commit('SET_FECHA_FINAL', new Date(moment()));
            commit('SET_ORIGEN_URL', null);
            commit('SET_CIUDAD_SELECTED', null);
            commit('SET_IMPORTE', 0);
            commit('SET_CANTIDAD_TIMBRE', null);
            commit('SET_CONFIRMAR_TIMBRE', null);
            commit('SET_CANTIDAD_ACTUAL', null);
            commit('SET_PAQUETE_SELECTED', null);
            commit('SET_FACTURACION_SELECTED', null);
            commit('SET_MONEDA_SELECTED', null);
            commit('SET_TIEMPO_SELECTED', null);
            commit('SET_AMBIENTE_SELECTED', null);
            commit('SET_PROVEEDOR_SOFTWARE_SELECTED', null);
            commit('SET_NUMERO_REGISTRO', null);
            commit('SET_DOCUMENTO_SELECTED', []);

            commit("SET_TOKEN_DELEGADO", null);
            commit("SET_VENCIMIENTO_TOKEN", null);
            commit("SET_EMPRESA_INTEGRACION_ID", null);
            commit("configuracion/theModalListToken/SET_LIS_TOKENS",  [], {root: true});
            commit("SET_ENTORNOS", []);
            commit("SET_ENTORNO_SELECTED", null);
            commit("SET_MEDICO_NUMERO", null);
            commit("SET_RAZON_SOCIAL_NUMERO", null);
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions,
}