const title = 'Usuarios';
const usuarioRoutes = [
    {
        path: 'usuario',
        name: 'usuario-index',
        component: () => import('../views/index.vue'),
        meta: {
            title,
        },
    }
];
export default usuarioRoutes;