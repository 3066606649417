import { make } from "vuex-pathify";
import axios from '@/modules/common/axiosFacturacion';
import { util } from '@/plugins/util';


const state = () => ({
    fields: [
        {
            key: 'editar', label: '', thStyle: {'width': '2%'}
        },
        {
            key: 'eliminar', label: '', thStyle: {'width': '2%'}
        },
        {
            key: 'certificado_digital', label: '', thStyle: {'width': '2%'}
        },
        {
            key: 'numero', label: 'N°', class: 'text-center', thStyle: { width: '5%' }, sortable: true,
        },
        {
            key: 'nombre_comercial', label: 'Empresa', class: 'text-center', thStyle: { width: '10%' }, sortable: true,
        },
        {
            key: 'nit', label: 'Nit', class: 'text-center', thStyle: { width: '5%' }, sortable: true,
        },
        {
            key: 'ambiente', label: 'Ambiente', class: 'text-center', thStyle: { width: '10%' },
        },
        {
            key: 'plan_erp', label: 'Plan ERP', class: 'text-center', thStyle: { width: '10%' }, sortable: true,
        },
        {
            key: 'paquete', label: 'Paquete', class: 'text-center', thStyle: { width: '10%' }, sortable: true,
        },
        {
            key: 'cantidad_timbre', label: 'Cant. Timbre', class: 'text-center', thStyle: { width: '10%' }, sortable: true,
        },
        {
            key: 'fecha_final', label: 'F/Venc.', class: 'text-center', thStyle: { width: '10%' }, sortable: true,
        },
        {
            key: 'empresa_paquete_fecha_final', label: 'Factura Actual', class: 'text-center', thStyle: { width: '10%' }, sortable: true,
        },
        {
            key: 'factura_final', label: 'Factura Final', class: 'text-center', thStyle: { width: '10%' }, sortable: true,
        },
    ],
    tablaEmpresas: [],
    estado: true,
    porPagina: 100,
    paginaOpciones: [100, 200],
    filter: '',
    paginaActual: 1,
    totalFila: 0,
    isLoading: true,
   
});

const mutations = make.mutations(state);

const getters = {};

const actions = {
    listarEmpresa({ state, commit }) {
        state.isLoading = true;
        const param = {
            page: state.paginaActual,
            per_page: state.porPagina,
            estado: state.estado,
            filter_key: state.filter,
        };
        axios.get('facturacion/empresa', { params: param })
        .then(response => {
            const DATA = response.data.data;
            commit("SET_TABLA_EMPRESAS", DATA.data.empresas);
            commit("SET_TOTAL_FILA", DATA.data.total);
        })
        .catch( error => {
            Object.entries(error.response.data.data).forEach(([, mensajes]) => {
                mensajes.forEach((texto) => util.showNotify(texto, 'error'));
            });
        }).finally(() => {
            state.isLoading = false;
        })
    },
    reset({ commit }) {
        commit("SET_FILTER", '');
        commit("SET_ESTADO", true);
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions,
}