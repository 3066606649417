import { util } from '@/plugins/util';
import axios from '@/modules/common/axiosFacturacion';
import { make } from 'vuex-pathify';

const state = () => ({
    fields: [
        {
            key: 'editar', label: '', class: 'text-center', thStyle: {width: '3%'}
        },
        {
            key: 'eliminar', label: '', class: 'text-center', thStyle: {width: '3%'}
        },
        {
            key: 'numero', label: 'N°',   thStyle: {width: '5%'}
        },
        {
            key: 'codigo_cliente', label: 'Codigo',  thStyle: {width: '8%'}
        },
        {
            key: 'nombre_comercial', label: 'Nombre',
        },
        {
            key: 'razon_social', label: 'Razon Social', 
        },
        {
            key: 'telefono', label: 'Telefono', 
        },
        {
            key: 'nit', label: 'NIT',  thStyle: {width: '9%'}
        },
        {
            key: 'direccion', label: 'Direccion',  thStyle: {width: '16%'}
        }
    ],
    clientes: [],
    estado: true,
    porPagina: 10,
    paginaActual: 1,
    totalFila: 0,
    paginaOpciones: [100, 200],
    filter: '',
    isBusy: true,
})

const mutations = make.mutations(state);

const getters = {};

const actions = {
    listarCliente({ state, commit }) {
        state.isBusy=true;
        const param = {
            page: state.paginaActual,
            per_page: state.porPagina,
            estado: state.estado,
            filter_key: state.filter,
        };
        axios.get('facturacion/cliente', { params: param})
        .then(response => {
            const DATA = response.data.data;
            console.log(DATA);
            commit("SET_CLIENTES", DATA.data.configuracions);
            commit("SET_TOTAL_FILA", DATA.data.total);
        })
        .catch(error => {
            console.log(error.response);
            Object.entries(error.response.data.data).forEach(([, mensajes]) =>{
                mensajes.forEach((texto) =>util.showNotify(texto, 'error'));
            });
        }).finally(() =>{
            state.isBusy = false;
        })
    },
    reset({ commit }){
        commit("SET_FILTER", '');
        commit("SET_ESTADO", true);
    },
};

export default {
    namespaced: true,
    state, 
    mutations,
    getters,
    actions,
}