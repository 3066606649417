import { make } from "vuex-pathify";
import axios from '@/modules/common/axiosFacturacion';
import { util } from '@/plugins/util';

const state = () => ({
    listaData: [],
    listaDataTabla: [],
    fechaSincronizacion: null,
    modo: null,
    page: 0,
    range: 10,
    total: 0,
    pages: 0,
    query: "",
    rangeToSelect: [10,20,30]
});

const mutations = make.mutations(state);
const getters = {
};

const actions = {
    listarTipoDocIdentidad({ commit }) {
        axios.get('siat/sincronizacion/tipodocumentoidentidad')
        .then( response => {
            commit('SET_FECHA_SINCRONIZACION', response.data.data.fecha_sync)
            commit('SET_MODO', response.data.data.modo)
            commit('SET_LISTA_DATA', response.data.data.tipo_docidentidad)
            commit('SET_TOTAL', response.data.data.tipo_docidentidad.length)
        }).catch( error => {
            util.showNotify(error.response.data.message, 'error');
        })
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions,
}