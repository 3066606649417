import { make } from "vuex-pathify";
import axios from '@/modules/common/axiosFacturacion';
import { util } from '@/plugins/util';

const state = () => ({
    nombreCompleto: null,
    mail: null,
    password: null,
    cambiarPassword: false,
    celular: null,
    archivo: null,
    estado: true,
    usuarioSelected:null,
    usuarios : [],
    paisSelected : null,
    paises : [], 
    clienteSelected : null,
    clientes : [],
    loading: false,
    empresas: [],
    empresa: null,
    showErrorNombreCompleto : false,
    showErrorMail : false,
    showErrorPassword : false,
    showErrorEmpresa : false,
    errorNombreCompleto : false,
    errorMail : false,
    errorPassword : false,
    errorEmpresa : false,
});

const mutations = make.mutations(state);

const getters = {
};

const actions = {
    async create({commit}){
        try {
            const {data} = await axios.get('facturacion/user/create');
            console.log(data)
            commit("SET_EMPRESAS", data.data.empresas)
        } catch (error) {
            const FALLO_VALIDACION = 422;
            if (error.response.status == FALLO_VALIDACION) {
                Object.entries(error.response.data.data).forEach(([, mensajes]) => {
                    mensajes.forEach((texto) => util.showNotify(texto, 'error'));
                });
            } else {
                util.showNotify(error.response.data.message, 'error');
            }
            return false;
        }
    },
    async store({ state, dispatch }) {
        try {
            if (await dispatch('validar')) {
                const REQUEST = new FormData();
                REQUEST.append('nombre_completo', state.nombreCompleto);
                REQUEST.append('email', state.mail);
                REQUEST.append('password', state.password);
                REQUEST.append('cambio_contrasena', JSON.stringify(state.cambiarPassword));
                REQUEST.append('celular', state.celular);
                REQUEST.append('imagen', state.archivo);
                REQUEST.append('empresa_id', state.empresa);
                REQUEST.append('estado_usuario', JSON.stringify(state.estado));
                const {data} = await axios.post('facturacion/user/store', REQUEST);
                util.showNotify(data.message, 'success');
                return true;
            }
        } catch (error) {
            const FALLO_VALIDACION = 422;
            if (error.response.status !== FALLO_VALIDACION) {
                util.showNotify(error.response.data.message, 'error');
            } else {
                Object.entries(error.response.data.data).forEach(([, mensajes]) => {
                    mensajes.forEach((texto) => util.showNotify(texto, 'error'));
                });
            }
            return false;
        }
    },
    async validar({ commit, state }) {
        commit('SET_SHOW_ERROR_MAIL', false)
        commit('SET_SHOW_ERROR_PASSWORD', false)
        commit('SET_SHOW_ERROR_NOMBRE_CCOMPLETO', false)
        commit('SET_SHOW_ERROR_EMPRESA', false)
        let valido = true;
        if (!state.mail) {
            util.showNotify('El campo mail es requerido', 'warn');
            commit('SET_SHOW_ERROR_MAIL', true)
            commit('SET_ERROR_MAIL', 'El campo mail es requerido')
            valido = false;
        }
        if (!state.password) {
            util.showNotify('El campo password es requerido', 'warn');
            commit('SET_SHOW_ERROR_PASSWORD', true)
            commit('SET_ERROR_PASSWORD', 'El campo password es requerido')
            valido = false;
        }
        if (!state.nombreCompleto) {
            util.showNotify('El campo nombre es requerido', 'warn');
            commit('SET_SHOW_ERROR_NOMBRE_COMPLETO', true)
            commit('SET_ERROR_NOMBRE_COMPLETO', 'El campo nombre es requerido')
            valido = false;
        }
        if (!state.empresa) {
            util.showNotify('El campo empresa es requerido', 'warn');
            commit('SET_SHOW_ERROR_EMPRESA', true)
            commit('SET_ERROR_EMPRESA', 'El campo empresa es requerido')
            valido = false;
        }
        return valido;
    },
    reset({ commit }) {
        commit('SET_NOMBRE_COMPLETO', null);
        commit('SET_MAIL', null);
        commit('SET_PASSWORD', null);
        commit('SET_CAMBIAR_PASSWORD', false);
        commit('SET_CELULAR', null);
        commit('SET_ARCHIVO', null);
        commit('SET_ESTADO', true);
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions,
}
