export const products = [
    {
        id:1,
        img: require("@/assets/images/products/iphone-1.jpg"),
        title: "Wireless Bluetooth V4.0 Portable Speaker with HD Sound and Bass",
        brand:'apple',
        productPrice: 50,
        discount: "20% off",
        rating:5,
        badgeColor: "badge-info",
        category: "Mobile"
        
    },
    {
        id:2,
        img: require("@/assets/images/products/headphone-1.jpg"),
        title: "Portable Speaker with HD Sound",
        brand:'samsung',
        rating:3,
        productPrice: 500,
        discount: "sale",
        badgeColor: "badge-primary",
        category: "Speaker"
    },
    {
        id:3,
        img: require("@/assets/images/products/speaker-1.jpg"),
        title: "Lightweight On-Ear Headphones - Black",
        brand:'apple',
        rating:4,
        productPrice: 50,
        discount: "40% ",
        badgeColor: "badge-danger",
        category: "Furniture"
    },
    {
        id:4,
        img: require("@/assets/images/products/speaker-2.jpg"),
        title: "Automatic-self-wind mens Watch 5102PR-001 (Cert",
        brand:'LG',
        rating:2,
        productPrice: 500,
        discount: "10% off",
        badgeColor: "badge-warning",
        category: "Watch"
    },
    {
        id:5,
        img: require("@/assets/images/products/headphone-2.jpg"),
        title: " Automatic-self-wind mens Watch 5102PR-001",
        brand:'sony',
        rating:5,
        productPrice: 1000,
        discount: "4% off",
        badgeColor: "badge-info",
        category: "Watch"
    },
    {
        id:6,
        img: require("@/assets/images/products/watch-2.jpg"),
        title: "On-Ear Headphones - Black",
        brand:'sony',
        rating:5,
        productPrice: 1000,
        discount: "20% off",
        badgeColor: "badge-primary",
        category: "Mobile"
    },
    {
        id:7,
        img: require("@/assets/images/products/iphone-1.jpg"),
        title: " In-Ear Headphone",
        brand:'sony',
        rating:4,
        productPrice: 200,
        discount: "20% off",
        badgeColor: "badge-info",
        category: "Speaker"
    },
    {
        id:8,
        img: require("@/assets/images/products/headphone-1.jpg"),
        title: "Duis exercitation nostrud anim",
        brand:'samsung',
        rating:3,
        productPrice: 7000,
        discount: "20% off",
        badgeColor: "badge-info",
        category: "Watch"
    },
    {
        id:9,
        img: require("@/assets/images/products/speaker-1.jpg"),
        title: " Lightweight On-Ear Headphones - Black",
        brand:'asus',
        rating:4,
        productPrice: 200,
        discount: "20% off",
        badgeColor: "badge-info",
        category: "Mobile"
    },
    {
        id:10,
        img: require("@/assets/images/products/speaker-2.jpg"),
        title: " Automatic-self-wind mens Watch 5102PR-001 (Certified P",
        brand:'huawei',
        rating:3,
        productPrice: 200,
        discount: "20% off",
        badgeColor: "badge-info",
        category: "Watch"
    },
    {
        id:11,
        img: require("@/assets/images/products/headphone-2.jpg"),
        title: "Automatic-self-wind mens Watch 5102PR-001",
        brand:'asus',
        rating:5,
        productPrice: 500,
        discount: "20% off",
        badgeColor: "badge-info",
        category: "Furniture"
    },
    {
        id:12,
        img: require("@/assets/images/products/watch-2.jpg"),
        title: " On-Ear Headphones - Black",
        brand:'xiaomi',
        rating:2,
        productPrice: 100,
        discount: "20% off",
        badgeColor: "badge-success",
        category: "Speaker"
    }
]