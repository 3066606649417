import { make } from "vuex-pathify";
import axios from '@/modules/common/axiosFacturacion';
import moment from "moment";
import { util } from '@/plugins/util';

const state = () => ({
    nombreComercial: null,
    razonSocial: null,
    nit: null,
    pais: [],
    paisSelected: null,
    ciudad: null,
    zonaHorarias: [],
    zonaHorariaSelected: null,
    fechaActivacion: new Date(moment()),
    sitioWeb: null,
    nombrePais: null,
    siglaPais: null,
    nombreSistema: null,
    codigoSistema: null,
    empresaProveedor: null,
    modalidadFacturas: [],
    modalidadFacturaSelected: null,
    ambientes: [],
    ambienteSelected: null,
    tokenDelegado: null,
    certificado: null,
    llavePrivada: null,
    isLoading: true,
    copiaCorreo: null,
    empresaId: null,
    urlImage: null,
    logotipo: null,
    fechaDesde: new Date(moment()),
    fechaHasta: new Date(moment()),
    colores: [],
    model_print: [],
    color: '#0D67A9',
    modelo: null,
    empresaIntegracion: null,
});

const mutations = make.mutations(state);

const getters = {};

const actions = {
    async getDatInicial({ commit }) {
        try {
            commit("SET_IS_LOADING", true);
            const RESPONSE = await axios.get(`facturacion/empresa/datos_empresa`);
            let data = RESPONSE.data
            commit("SET_EMPRESA_ID", data.data.empresa.id);
            commit("SET_NOMBRE_COMERCIAL", data.data.empresa.nombre_comercial);
            commit("SET_RAZON_SOCIAL", data.data.empresa.razon_social);
            commit("SET_NIT", data.data.empresa.nit);
            commit("SET_CIUDAD", data.data.empresa.ciudad);
            commit("SET_PAIS", data.data.pais);
            const PAIS = data.data.pais;
            const PAIS_ID  = data.data.empresa.pais_id;
            const PAIS_SELECTED = PAIS.find((ele) => (
                ele.id == PAIS_ID
            ));
            commit("SET_PAIS_SELECTED", PAIS_SELECTED);

            const ZONA_HORARIAS = data.data.zona_horarias;
            const ZONA_HORARIA_ID = data.data.empresa.zona_horaria_id;
            commit("SET_ZONA_HORARIAS", ZONA_HORARIAS);
            const ZONA_HORARIA_SELECTED = ZONA_HORARIAS.find((el) => (
                el.id == ZONA_HORARIA_ID
            ));

            commit("SET_ZONA_HORARIA_SELECTED", ZONA_HORARIA_SELECTED);
            commit("SET_COPIA_CORREO", data.data.empresa.copia_correo);
            commit("SET_SITIO_WEB", data.data.empresa.sitio_web);
            commit("SET_NOMBRE_PAIS", data.data.empresa.nombre_pais);
            commit("SET_SIGLA_PAIS", data.data.empresa.sigla_pais);

            commit("SET_NOMBRE_SISTEMA", data.data.empresa.nombre_sistema_proveedor);
            commit("SET_CODIGO_SISTEMA", data.data.empresa.codigo_sistema_proveedor);
            commit("SET_EMPRESA_PROVEEDOR", data.data.empresa.empresa_proveedor);

            commit("SET_MODALIDAD_FACTURAS", data.data.facturacions);
            commit("SET_AMBIENTES", data.data.ambientes);
            commit("SET_CERTIFICADO", data.data.empresa.llave_publica);
            commit("SET_LLAVE_PRIVADA", data.data.empresa.llave_privada);
            commit("SET_FECHA_DESDE", new Date(moment(data.data.empresa.fecha_desde)));
            commit("SET_FECHA_HASTA", new Date(moment(data.data.empresa.fecha_hasta)));

            const FACTURACIONS = data.data.facturacions;
            const FACTURACIONS_ID = data.data.empresa.siat_modalidad_id;
            const FACTURACION_SELECTED = FACTURACIONS.find((ele) => (
                ele.id === FACTURACIONS_ID
            ));
            commit('SET_MODALIDAD_FACTURA_SELECTED', FACTURACION_SELECTED);

            commit("SET_COLORES", data.data.colores);
            commit("SET_MODEL_PRINT", data.data.model_print);
            if(data.data.empresa.color_id) {
                commit("SET_COLOR", data.data.colores.filter(e => e.id == data.data.empresa.color_id)[0].color)
            }
            if(data.data.empresa.model_print_id) {
                commit("SET_MODELO", data.data.model_print.filter(e => e.id == data.data.empresa.model_print_id)[0])
            }
            
            const AMBIENTES = data.data.ambientes;
            const AMBIENTE_ID = data.data.empresa.siat_ambiente_id;
            const AMBIENTE_SELECTED = AMBIENTES.find((ele) => (
                ele.id === AMBIENTE_ID
            ));
            commit('SET_AMBIENTE_SELECTED', AMBIENTE_SELECTED);
            const TOKEN_EMSIOR = data.data.codigo_integracion ? data.data.codigo_integracion.token_emisor : null;
            const TOKEN_ACTIVO = data.data.codigo_integracion ? data.data.codigo_integracion.is_active : false;
            commit('empresa/generateCodigoIntegracion/SET_CODIGO_GENERADO', TOKEN_EMSIOR, { root: true });
            commit('empresa/generateCodigoIntegracion/SET_DOMINIO', data.data.empresa.dominio_integracion_front , { root: true });
            commit('empresa/generateCodigoIntegracion/SET_TOKEN_ACTIVO', TOKEN_ACTIVO , { root: true });
            commit('SET_EMPRESA_INTEGRACION', data.data.empresa.empresa_integracion_id);
            

            
        } catch (error) {
            console.log("error", error);
        } finally {
            commit("SET_IS_LOADING", false);
        }
    },
    async update({ commit, dispatch, state}) {
        try {
            if (await dispatch('validar')) {
                commit("SET_IS_LOADING", true);
                const PAIS_ID = state.paisSelected ? state.paisSelected.id : '';
                const ZONA_HORAIA_ID = state.zonaHorariaSelected ? state.zonaHorariaSelected.id : '';
                const MODALIDAD_FACTURA_ID = state.modalidadFacturaSelected ? state.modalidadFacturaSelected.id : '';
                const AMBIENTE_ID = state.ambienteSelected ? state.ambienteSelected.id : '';
                const LOGOTIPO = state.logotipo ? state.logotipo : '';

                const FORM_DATA = new FormData();
                FORM_DATA.append('nombre_comercial', state.nombreComercial);
                FORM_DATA.append('razon_social', state.razonSocial);
                FORM_DATA.append('nit', state.nit);
                FORM_DATA.append('pais_id', PAIS_ID);
                FORM_DATA.append('copia_correo', state.copiaCorreo);
                FORM_DATA.append('ciudad', state.ciudad);
                FORM_DATA.append('zona_horaria_id', ZONA_HORAIA_ID);
                FORM_DATA.append('sitio_web', state.sitioWeb ? state.sitioWeb : '');
                FORM_DATA.append('nombre_pais', state.nombrePais);
                FORM_DATA.append('sigla_pais', state.siglaPais);
                FORM_DATA.append('empresa_proveedor', state.empresaProveedor ? state.empresaProveedor : '');
                FORM_DATA.append('nombre_sistema', state.nombreSistema ? state.nombreSistema : '');
                FORM_DATA.append('codigo_sistema', state.codigoSistema ? state.codigoSistema : '');
                FORM_DATA.append('modalidad_factura_id', MODALIDAD_FACTURA_ID);
                FORM_DATA.append('ambiente_id', AMBIENTE_ID);
                FORM_DATA.append('certificado', state.certificado ? state.certificado : '');
                FORM_DATA.append('llave_privada', state.llavePrivada ? state.llavePrivada : '');
                FORM_DATA.append('logotipo', LOGOTIPO);
                FORM_DATA.append('fecha_desde', state.fechaDesde ? moment(state.fechaDesde).format('YYYY-MM-DD') : '') ;
                FORM_DATA.append('fecha_hasta', state.fechaHasta ? moment(state.fechaHasta).format('YYYY-MM-DD') : '') ;
                FORM_DATA.append('modelo', state.modelo ? state.modelo.id : '');
                FORM_DATA.append('color', state.color ? state.colores.filter(e => e.color == state.color)[0]['id'] : '');
                const {data} = await axios.post(`facturacion/empresa/datos_empresa`, FORM_DATA);
                util.showNotify(data.message, 'success');
                dispatch('getDatInicial');
                return true;
            }
        } catch (error) {
            const FALLO_VALIDACION = 422;
            if (error.response.status !== FALLO_VALIDACION) {
                util.showNotify(error.response.data.message, 'error');
            } else {
                Object.entries(error.response.data.data).forEach(([, mensajes]) => {
                    mensajes.forEach((texto) => util.showNotify(texto, 'error'));
                });
            }
            return false;
        } finally {
            commit("SET_IS_LOADING", false);
        }
    },
    validar({state}) {
        let valido = true;
        if (!state.paisSelected) {
            util.showNotify('El pais es un campo requerido', 'error');
            valido = false;
        }
        if (!state.zonaHorariaSelected) {
            util.showNotify('La zona horaria es un campo requerido', 'error');
            valido = false;
        }
        if (!state.nombrePais) {
            util.showNotify('El nombre del pais es un campo requerido', 'error');
            valido = false;
        }
        if (!state.siglaPais) {
            util.showNotify('La sigla del pais es un campo requerido', 'error');
            valido = false;
        }
        if (!state.copiaCorreo) {
            util.showNotify('La copia de correo es un campo requerido', 'error');
            valido = false;
        }
        if (!state.modalidadFacturaSelected) {
            util.showNotify('La modalidad de factura es un campo requerido', 'error');
            valido = false;
        }
        if (!state.ambienteSelected) {
            util.showNotify('El ambiente es un campo requerido', 'error');
            valido = false;
        }
        return valido;
    },
    reset({commit}) {
        commit("SET_NOMBRE_COMERCIAL", null);
        commit("SET_RAZON_SOCIAL", null);
        commit("SET_NIT");
        commit("SET_PAIS", []);
        commit("SET_PAIS_SELECTED", null);
        commit("SET_CIUDAD");
        commit("SET_ZONA_HORARIAS", []);
        commit("SET_ZONA_HORARIA_SELECTED", null);
        commit("SET_FECHA_ACTIVACION", new Date(moment()));
        commit("SET_SITIO_WEB", null);
        commit("SET_NOMBRE_PAIS", null);
        commit("SET_SIGLA_PAIS", null);
        commit("SET_NOMBRE_SISTEMA", null);
        commit("SET_CODIGO_SISTEMA", null);
        commit("SET_EMPRESA_PROVEEDOR", null);
        commit("SET_MODALIDAD_FACTURAS", []);
        commit("SET_MODALIDAD_FACTURA_SELECTED", null);
        commit("SET_AMBIENTES", []);
        commit("SET_AMBIENTE_SELECTED", null);
        commit("SET_TOKEN_DELEGADO", null);
        commit("SET_CERTIFICADO", null);
        commit("SET_LLAVE_PRIVADA", null);
        commit("SET_IS_LOADING", true);
        commit("SET_COPIA_CORREO", null);
        commit("SET_EMPRESA_ID", null);
        commit("SET_LOGOTIPO", null);
        commit("SET_URL_IMAGE", null);
        commit("SET_FECHA_DESDE", null);
        commit("SET_FECHA_HASTA", null);
        commit("SET_EMPRESA_INTEGRACION", null);
    }

};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions,
}