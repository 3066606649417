import { make } from "vuex-pathify";
import axios from '@/modules/common/axiosFacturacion';
import moment from 'moment';



const state = () => ({
  sincronizando : false,
  loadingCuis: false,
  cuis: {
    numero: '0'
  },
  fechaSincronizacion: null,
});

const mutations = make.mutations(state);
const getters = {
};

const actions = {
    obtenerCuis({commit}){
      commit('SET_LOADING_CUIS', true)
      axios.get('siat/connection/cuis')
      .then( response => {
        let fecha = moment(response.data.data.fecha_sync).format('DD/MM/YYYY')
        commit('SET_FECHA_SINCRONIZACION', fecha)
        if(response.data.data.cuis.length > 0)
          commit('SET_CUIS', response.data.data.cuis.pop())
        else
          commit('SET_CUIS', {
            numero: '0'
          })
        
      }).catch( error => {
        console.log('error', error)
      })
      commit('SET_LOADING_CUIS', false)
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions,
}