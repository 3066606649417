import { util } from '@/plugins/util';
import axios from '@/modules/common/axiosFacturacion'
import { make } from 'vuex-pathify';

const state = () => ({
    tiempos: [],
    isLoading: true,
    tiempoSelected: "",
    modalidades: [],
    modalidadSelected: null,
    tipos: [],
    tipoSelected: null,
    desplegar: false,
    monedas: [],
    monedaSelected: null,
    importeTotal: 0,
    nombrePaquete: null,
    cantidadTimbre : "",
    numeroRegistro: null,
});

const mutations = make.mutations(state);

const getters = {};

const actions = {
    async getDatoInicial({ commit }, id) {
        try {
            const RESPONSE = await axios.get(`/facturacion/paquete/${id}/edit`);
            const TIPOS = RESPONSE.data.data.tipo_paquetes;
            const MONEDAS = RESPONSE.data.data.monedas;
            const MODALIDADES = RESPONSE.data.data.modalidad_paquetes;
            const TIEMPOS = RESPONSE.data.data.tiempo_paquetes;
            
            const PAQUETE_ID = RESPONSE.data.data.paquete.tipo_paquete_id;
            const MONEDA_ID = RESPONSE.data.data.paquete.moneda_id;
            const MODALIDAD_ID = RESPONSE.data.data.paquete.modalidad_paquete_id;
            const TIEMPO_ID = RESPONSE.data.data.paquete.tiempo_paquete_id;

            const TIPO_SELECTED = TIPOS.find((ele) => (ele.id === PAQUETE_ID));
            const MONEDA_SELECTED = MONEDAS.find((ele) => (ele.id === MONEDA_ID));
            const MODALIDAD_SELECTED = MODALIDADES.find((ele) => (ele.id === MODALIDAD_ID));
            const TIEMPO_SELECTED = TIEMPOS.find((ele) => (ele.id === TIEMPO_ID));
            var valor1;
            if(TIEMPO_SELECTED == undefined){
                valor1 = "";
            }else{
                valor1 = TIEMPO_SELECTED;
            }
            commit("SET_NOMBRE_PAQUETE", RESPONSE.data.data.paquete.nombre);
            commit("SET_IMPORTE_TOTAL", RESPONSE.data.data.paquete.importe_total);
            commit("SET_CANTIDAD_TIMBRE", RESPONSE.data.data.paquete.cantidad_timbre);
            commit("SET_TIEMPOS", TIEMPOS);
            commit("SET_TIEMPO_SELECTED", valor1);
            commit("SET_MODALIDADES", MODALIDADES);
            commit("SET_MODALIDAD_SELECTED", MODALIDAD_SELECTED);
            commit("SET_TIPOS", TIPOS);
            commit("SET_TIPO_SELECTED", TIPO_SELECTED);
            commit("SET_DESPLEGAR", false);
            commit("SET_MONEDAS", MONEDAS);
            commit("SET_MONEDA_SELECTED", MONEDA_SELECTED);
            commit("SET_IMPORTE_TOTAL", RESPONSE.data.data.paquete.importe_total);
            commit("SET_NUMERO_REGISTRO", RESPONSE.data.data.paquete.numero);
            return true; 
        } catch (error) {
            Object.entries(error.response.data.data).forEach(([, mensajes])=>{
                mensajes.forEach((texto) => util.showNotify(texto, 'error'));
            });
            return false;
        }
    },
    async updatePaquete({ state, dispatch }, id) {
        try {
            if(await dispatch('validar')) { 
                const TIEMPO_SELECTED = state.tiempoSelected ? state.tiempoSelected.id : '';
                const REQUEST = new FormData();
                REQUEST.append('nombre', state.nombrePaquete);
                REQUEST.append('importe_total', state.importeTotal);
                REQUEST.append('cantidad_timbre', state.cantidadTimbre ? state.cantidadTimbre : '');
                REQUEST.append('modalidad_paquete_id', state.modalidadSelected.id);
                REQUEST.append('moneda_id', state.monedaSelected.id);
                REQUEST.append('tipo_paquete_id', state.tipoSelected.id);
                REQUEST.append('tiempo_paquete_id', TIEMPO_SELECTED);
                const {data} = await axios.post(`facturacion/paquete/${id}/update`, REQUEST);
                util.showNotify(data.message, 'success');
                return true;
            }
        } catch (error) {
            const FALLO_VALIDACION = 422;
            if (error.response.status !== FALLO_VALIDACION) {
                util.showNotify(error.response.data.message, 'error');
            }else{
                Object.entries(error.response.data.data).forEach(([, mensajes]) => {
                    mensajes.forEach((texto) => util.showNotify(texto, 'error'));
                });
            }
            return false;
        }
    },
    validar({ state }) {
        let valido = true;
        if (!state.nombrePaquete) {
            util.showNotify('El campo nombre paquete es requerido', 'warn');
            valido = false;
        }
        if (!state.modalidadSelected) {
            util.showNotify('El campo modalidad es requerido', 'warn');
            valido = false;
        }
        if (!state.monedaSelected) {
            util.showNotify('El campo moneda es requerido', 'warn');
            valido = false;
        }
        if (!state.tipoSelected) {
            util.showNotify('El campo tipo es requerido', 'warn');
            valido = false;
        }
        return valido;
    },
    reset({ commit }) {
        commit("SET_IS_LOADING", true);
        commit("SET_NOMBRE_PAQUETE", null);
        commit("SET_IMPORTE_TOTAL", null);
        commit("SET_CANTIDAD_TIMBRE", null);
        commit("SET_TIEMPOS", []);
        commit("SET_TIEMPO_SELECTED", "");
        commit("SET_MODALIDADES", []);
        commit("SET_MODALIDAD_SELECTED", null);
        commit("SET_TIPOS", []);
        commit("SET_TIPO_SELECTED", null);
        commit("SET_DESPLEGAR", false);
        commit("SET_MONEDAS", []);
        commit("SET_MONEDA_SELECTED", null);
        commit("SET_IMPORTE_TOTAL", null);
    },
};

export default {
    namespaced: true,
    state, 
    mutations,
    getters,
    actions,
}