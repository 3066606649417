import Vue from "vue";
import Notifications from 'vue-notification';
import App from "./App.vue";
import router from "./router";
import GullKit from "./plugins/gull.kit";
import store from "./store";
import "font-awesome/css/font-awesome.min.css";
import firebase from "firebase/app";
import {firebaseSettings} from "@/data/config";
import "firebase/auth";
import 'vue-multiselect/dist/vue-multiselect.min.css';
import vueNumeralFilterInstaller from 'vue-numeral-filter';

Vue.component('VueFontawesome', require('vue-fontawesome-icon/VueFontawesome.vue').default);
Vue.use(GullKit);
Vue.use(Notifications);
firebase.initializeApp(firebaseSettings);
Vue.config.productionTip = false;
Vue.use(vueNumeralFilterInstaller);

import Echo from 'laravel-echo'
window.Pusher = require('pusher-js');
window.Echo = new Echo({
  broadcaster: process.env.VUE_APP_BROADCAST,
  key: process.env.VUE_APP_KEY,
  wsHost: process.env.VUE_APP_HOST,
  cluster: process.env.VUE_APP_CLUSTER,
  wsPort: process.env.VUE_APP_POST_HTTP,
  wssPort: process.env.VUE_APP_POST_HTTPS,
  forceTLS: process.env.VUE_APP_FORCE_TLS === 'true',
  disableStats: process.env.VUE_APP_DISABLE_STATS === 'true',
  enabledTransports: ['ws', 'wss'],
  auth: { headers: { authorization: 'Bearer ' + localStorage.getItem('user-token'),}}
});

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount("#app");
