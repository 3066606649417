import { make } from 'vuex-pathify';
import axios from '@/modules/common/axiosFacturacion';
import { util } from '@/plugins/util';
import moment from "moment";
// import { email } from 'vuelidate/lib/validators';

const state = () => ({
    nombreComercial: null,
    empresa: {
        texto_numero_contribuyente:"NIT",
        texto_razon_social:"Razon Social"
    },
    isLoading: true, 
    codigoCliente: null,
    fechaTrabajo: moment(state.fechaTrabajo).format('YYYY-MM-DD'),
    vendedor: null,
    vendedores: [],
    tipoClienteSelected: null,
    tipoClientes: [],
    celularTelefono: null,
    tag: '',
    tags : [],
    // emails: [],
    direccion: null,
    paisSelected: null,
    paises: [],
    ciudad: null,
    limiteCredito: null,
    origenCliente: null,
    origenesClientes: [],
    descripcion: null,
    loaded: true,
    razonSocial: null,
    ciNit: null,
});

const mutations = make.mutations(state);

const getters = {};

const actions = {
    async getPaises({commit}) {
        try {
            const RESPONSE = await axios.get('/facturacion/cliente/nuevo');
            commit("SET_PAISES", RESPONSE.data.data.paises);
            return true;
        } catch (error) {
            Object.entries(error.response.data.data).forEach(([, mensajes]) => {
            mensajes.forEach((texto) => util.showNotify(texto, 'texto'));
            });
            return false;
        }
    }, 
    async getCliente({commit}) {
        try {
            const RESPONSE = await axios.get('/facturacion/cliente/nuevo');
            commit("SET_ORIGENES_CLIENTES", RESPONSE.data.data.origenesClientes);
            return true;
        } catch (error) {
            Object.entries(error.response.data.data).forEach(([, mensajes]) => {
            mensajes.forEach((texto) => util.showNotify(texto, 'texto'));
            });
            return false;
        }
    }, 
    async store({ state, dispatch }) {
       
        try {
            if(await dispatch('validar')){
                    var REQUEST = {
                        nombre_comercial: state.nombreComercial,
                        razon_social: state.razonSocial,
                        nit:state.ciNit,
                        codigo_cliente: state.codigoCliente,
                        fecha_trabajo: moment(state.fecha_trabajo).format('YYYY-MM-DD'),
                        telefono: state.celularTelefono,
                        emails: state.tags,
                        direccion: state.direccion,
                        pais_id: state.paisSelected.id,
                        ciudad_id: state.ciudad,
                        limite_credito: state.limiteCredito,
                        descripcion: state.descripcion,
                        origen_cliente_id: state.origenCliente.id ? state.origenCliente.id : null,
                        };
                        const {data} = await axios.post('facturacion/cliente', REQUEST);
                        util.showNotify(data.message, 'success');
                       return true;
                                                        
                    }
        
             } catch (error) {
            const FALLO_VALIDACION = 422;
            if (error.response.status !== FALLO_VALIDACION){
                util.showNotify(error.response.data.message, 'error');

            }else{
                Object.entries(error.response.data.data).forEach(([, mensaje]) =>{
                    mensaje.forEach((texto) => util.showNotify(texto, 'error'));
                });

            }
            return false;
        }
    },
    validar({state}) {
        let valido = true;
        if(!state.tags){
            util.showNotify('el campo email es requerido', 'warn');
            valido = false;
        }
        return valido;
    },

    resetFormularioCliente({ commit }) {
        console.log('RESET_CLIENTE');
        commit('SET_NOMBRE_COMERCIAL', null);
        // commit('setEmpresa', {});
        commit('SET_CODIGO_CLIENTE', null);
        commit('SET_FECHA_TRABAJO', moment(new Date()).format('YYYY-MM-D'));
        commit('SET_VENDEDOR', null);
        commit('SET_VENDEDORES', []);
        commit('SET_TIPO_CLIENTE_SELECTED', null);
        commit('SET_TIPO_CLIENTES', []);
        commit('SET_CELULAR_TELEFONO', null);
        commit('SET_TAG', '');
        commit('SET_TAGS', []);
        commit('SET_DIRECCION', null);
        commit('SET_PAIS_SELECTED', null);
        commit('SET_PAISES', []);
        commit('SET_CIUDAD', null);
        commit('SET_LIMITE_CREDITO', null);
        commit('SET_ORIGEN_CLIENTE', null);
        commit('SET_ORIGENES_CLIENTES', []);
        commit('SET_DESCRIPCION', null);
        commit('SET_RAZON_SOCIAL', null);
        commit('SET_CI_NIT', null);
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions,
}