import { make } from "vuex-pathify";
import axios from '@/modules/common/axiosFacturacion';
import { util } from '@/plugins/util';
import moment from "moment";

const state = () => ({
    estado: true,
    page: 1,
    porPagina: 100,
    paginaOpciones: [100, 200],
    filter: null,
    delayTimeout: null,
    fields: [
        { key: 'numero', label: 'N°', class: 'text-center', thStyle: { width: '3%' }, },
        { key: 'sucursal', label: 'Sucursal', class: 'text-center', thStyle: { width: '10%' }, },
        { key: 'puntoventa', label: 'P.Venta', class: 'text-center', thStyle: { width: '10%' }, },
        { key: 'codigo_recepcion', label: 'Cod.Recep', class: 'text-center', thStyle: { width: '8%' }, },
        { key: 'descripcion', label: 'Motivo', class: 'text-center', thStyle: { width: '15%' }, },
        { key: 'hora_inicio', label: 'H.Incio', class: 'text-center', thStyle: { width: '8%' }, },
        { key: 'hora_fin', label: 'H.Fin', class: 'text-center', thStyle: { width: '8%' }, },
        { key: 'cufd', label: 'Cufd', class: 'text-center', thStyle: { width: '25%' }, },
        { key: 'estado', label: 'Estado', class: 'text-center', thStyle: { width: '10%' }, },
    ],
    listaEventos: [],
    loading: false,
    total: null,
    siat_puntoventa_id: null,
    siat_sucursal_id: null,
    sucursales: [],
    punto_ventas: [],
    fecha:  new Date(moment()),
    listaConsultas: [],
    fields_consulta: [
        { key: 'numero', label: 'Nro', class: 'text-center', thStyle: { width: '3%' }, },
        { key: 'codigo_recepcion', label: 'codigo', class: 'text-center', thStyle: { width: '10%' }, },
        { key: 'descripcion', label: 'Descripcion', class: 'text-center', thStyle: { width: '50%' }, },
        { key: 'fecha_inicio', label: 'F./Inicio', class: 'text-center', thStyle: { width: '10%' }, },
        { key: 'fecha_fin', label: 'F./Fin', class: 'text-center', thStyle: { width: '10%' }, },
    ]
});

const mutations = make.mutations(state);

const getters = {};

const actions = {
    async getIndex({state, commit}) {
        try {
            commit("SET_LOADING", true)
            const param = {
                filter_key: state.filter,
                page: state.page,
                per_page: state.porPagina,
            };
            const { data } = await axios.get('siat/operaciones/evento', { params: param });
            commit("SET_LISTA_EVENTOS", [])
            commit("SET_LISTA_EVENTOS", data.data.data)
            commit("SET_TOTAL", data.data.total)
        } catch (error) {
            util.showNotify(error.response.data.message, 'error');                
        }finally{
            commit("SET_LOADING", false)
        }
    },
    async getCreate({commit}) {
        try {
            commit("SET_LOADING", true)
            const { data } = await axios.get('siat/operaciones/evento/create');
            commit("SET_SUCURSALES", data.data.sucursales)
            commit("SET_PUNTO_VENTAS", data.data.punto_ventas)
        } catch (error) {
            util.showNotify(error.response.data.message, 'error');
        }finally{
            commit("SET_LOADING", false)
        }
    },
    async setCloseEvent({commit, dispatch}, id) {
        try {
            commit("SET_LOADING", true)
            const { data } = await axios.get('siat/operaciones/evento/' + id);
            util.showNotify(data.message, 'success');
            dispatch('getIndex')
        } catch (error) {
            util.showNotify(error.response.data.message, 'error');
        }finally{
            commit("SET_LOADING", false)
        }
    },
    async setClosePackage({commit, dispatch}, id) {
        try {
            commit("SET_LOADING", true)
            const { data } = await axios.get('siat/compraventa/paquete/' + id);
            util.showNotify(data.message, 'success');
            dispatch('getIndex')
        } catch (error) {
            util.showNotify(error.response.data.message, 'error');
        }finally{
            commit("SET_LOADING", false)
        }
    },
    async setValidate({commit, dispatch}, id) {
        try {
            commit("SET_LOADING", true)
            const { data } = await axios.get('siat/compraventa/validacion/' + id);
            util.showNotify(data.message, 'success');
            dispatch('getIndex')
        } catch (error) {
            util.showNotify(error.response.data.message, 'error');
        }finally{
            commit("SET_LOADING", false)
        }
    },
    async getEventConsult({state, commit, dispatch}) {
        try {
            if(await dispatch("validar")) {
                commit("SET_LOADING", true)
                commit("SET_LISTA_CONSULTAS", [])
                const param = {
                    siat_sucursal_id: state.siat_sucursal_id.id,
                    siat_puntoventa_id: state.siat_puntoventa_id.id,
                    fecha: state.fecha,
                };
                const { data } = await axios.get('siat/operaciones/evento/consulta', { params: param });
                commit("SET_LISTA_CONSULTAS", data.data)
                util.showNotify(data.message, 'success');
            }
        } catch (error) {
            util.showNotify(error.response.data.message, 'error');
        } finally {
            commit("SET_LOADING", false)
        }
    },
    validar({state}) { 
        let valido = true;
        if (!state.siat_sucursal_id) {
            util.showNotify('La Sucursal es un campo requerido', 'error');
            valido = false;
        }
        if (!state.siat_puntoventa_id) {
            util.showNotify('El Punto de Venta es un campo requerido', 'error');
            valido = false;
        }
        if (!state.fecha) {
            util.showNotify('La Fecha es un campo requerido', 'error');
            valido = false;
        }

        return valido;
    }
};

export default {
    namespaced: true,
    state, 
    mutations,
    getters,
    actions,
}