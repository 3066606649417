import { make } from 'vuex-pathify';
// import axios from '@/modules/common/axiosFacturacion';
// import { util } from '@/plugins/util';

const state = () => ({
    fieldsFirmaDigital : [
        {
            key: 'usuario', label: 'Usuario', thStyle: {'width': '30%'}, sortable: false,
        },
        {
            key: 'fecha', label: 'Fecha', thStyle: {'width': '10%'}, sortable: false,
        },
        {
            key: 'privada', label: 'Privada', thStyle: {'width': '20%'}, sortable: false,
        },
        {
            key: 'publica', label: 'Publica', thStyle: {'width': '20%'}, sortable: false,
        },
        {
            key: 'vence', label: 'Vence', thStyle: {'width': '10%'}, sortable: false,
        },
        {
            key: 'estado', label: 'Estado', thStyle: {'width': '10%'}, sortable: false,
        },
    ],
    tablaFirmas: [],
    isLoadingFirmas: true,
    porPagina: 100,
    paginaOpciones: [100, 200],
    paginaActual: 1,
    totalFila: 0,
    companyFirma: null,
    showModalFirmaDigital: false,

    llavePrivada: '',
    llavePublica: '',
    vence: '',
});

const mutations = make.mutations(state);

const getters = {};

const actions = {
    reset({ commit }) {
        commit('SET_COMPANY_FIRMA', null);
        commit('SET_SHOW_MODAL_FIRMA_DIGITAL', false);
    },
    listarFirmaDigital({state, commit}){
        console.log(commit)
        state.isLoadingFirmas = false
        // state.isLoadingFirmas = true
        // const param = {
        //     page: state.paginaActual,
        //     per_page: state.porPagina,
        //     estado: state.estado,
        //     filter_key: state.filter,
        // };
        // axios.get('firmas-digitales/empresa', { params: param })
        // .then(response => {
        //     const DATA = response.data.data;
        //     console.log(DATA);
        //     commit("SET_TABLA_EMPRESAS", DATA.data.empresas);
        //     commit("SET_TOTAL_FILA", DATA.data.total);
        // })
        // .catch( error => {
        //     Object.entries(error.response.data.data).forEach(([, mensajes]) => {
        //         mensajes.forEach((texto) => util.showNotify(texto, 'error'));
        //     });
        // }).finally(() => {
        //     state.isLoading = false;
        // })
    },

    async firmaDigital({ dispatch }, item) {
      console.log("Accion de generacion de firma digital")
      console.log(dispatch, item)

        // try {
        //   const { data } = await axios.delete(`facturacion/paquete/${item.id}`);
        //   util.showNotify(data.message, 'success');
        //   dispatch('reset');
        //   return true;
        // } catch (error) {
        //   util.showNotify(error.response.data.message, 'error');
        //   return false;
        // }
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions,
};