import { make } from "vuex-pathify";
import axios from '@/modules/common/axiosFacturacion';
import { util } from '@/plugins/util';

const state = () => ({
    nombreCompleto: null,
    mail: null,
    password: null,
    cambiarPassword: false,
    celular: null,
    archivo: null,
    estado: true,
    urlImage: null,
    numero: null,
    usuarioSelected:null,
    usuarios : [],
    paisSelected : null,
    paises : [], 
    clienteSelected : null,
    clientes : [],
    empresas: [],
    empresa: null,
    showErrorNombreCompleto : false,
    showErrorMail : false,
    showErrorPassword : false,
    showErrorEmpresa : false,
    errorNombreCompleto : false,
    errorMail : false,
    errorPassword : false,
    errorEmpresa : false,
});

const mutations = make.mutations(state);

const getters = {};

const actions = {
    async editUser( { commit }, id) {
        try {
            const {data} = await axios.get(`facturacion/user/${id}/edit`);
            let estado = data.data.user.estado_usuario === 1 ? true : false;
            let cambiarPassword = data.data.user.cambio_contrasena === 1 ? true : false;
            commit("SET_NOMBRE_COMPLETO", data.data.user.nombre_completo);
            commit("SET_MAIL", data.data.user.email);
            commit("SET_PASSWORD", data.data.user.password);
            commit("SET_CAMBIAR_PASSWORD", cambiarPassword);
            commit("SET_CELULAR", data.data.user.celular != 'null' ? data.data.user.celular : '');
            commit("SET_URL_IMAGE", data.data.user.imagen);
            commit("SET_ESTADO", estado);
            commit("SET_NUMERO", data.data.user.numero);
            commit("SET_EMPRESAS", data.data.empresas);
            commit("SET_EMPRESA", data.data.user.empresa_id);
            return true;
        } catch (error) {
            util.showNotify(error.response.data.message, 'error');
            return false;
        }
    },
    async updateUser({ state, dispatch }, id) {
        try {
            if (await dispatch('validar')) {
                const REQUEST = new FormData();
                REQUEST.append('nombre_completo', state.nombreCompleto);
                REQUEST.append('email', state.mail);
                REQUEST.append('password', state.password == null || state.password == '' ? '' : state.password);
                REQUEST.append('cambio_contrasena', JSON.stringify(state.cambiarPassword));
                REQUEST.append('celular', state.celular);
                REQUEST.append('imagen', state.archivo ? state.archivo : '');
                REQUEST.append('estado_usuario', JSON.stringify(state.estado));
                REQUEST.append('empresa_id', state.empresa);
                const {data} = await axios.post(`facturacion/user/${id}/update`, REQUEST);
                util.showNotify(data.message, 'success');
                return true;
            }
        } catch (error) {
            const FALLO_VALIDACION = 422;
            if (error.response.status !== FALLO_VALIDACION) {
                util.showNotify(error.response.data.message, 'error');
            } else {
                Object.entries(error.response.data.data).forEach(([, mensajes]) => {
                    mensajes.forEach((texto) => util.showNotify(texto, 'error'));
                });
            }
            return false;
        }
    },
    async validar({ state }) {
        let valido = true;
        if (!state.mail) {
            util.showNotify('El campo mail es requerido', 'warn');
            valido = false;
        }
        if (!state.nombreCompleto) {
            util.showNotify('El campo nombre es requerido', 'warn');
            valido = false;
        }
        return valido;
    },
    reset({ commit }) {
        commit('SET_NOMBRE_COMPLETO', null);
        commit('SET_MAIL', null);
        commit('SET_PASSWORD', null);
        commit('SET_CAMBIAR_PASSWORD', false);
        commit('SET_CELULAR', null);
        commit('SET_ARCHIVO', null);
        commit('SET_ESTADO', true);
        commit('SET_NUMERO', true);
        commit('SET_EMPRESA', null);
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions,
}
