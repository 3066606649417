import empresaIndex from "./apis/index";
import empresaStore from "./apis/store";
import generateCodigoIntegracion from "./apis/generateCodigoIntegracion";
import firmaDigital from "./apis/firmaDigital";
import empresaUpdate from "./apis/update";
import empresaDestroy from "./apis/destroy";

export default {
    namespaced: true,
    modules: {
        empresaIndex,
        empresaStore,
        generateCodigoIntegracion,
        firmaDigital,
        empresaUpdate,
        empresaDestroy
    },
}