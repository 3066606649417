import { make } from "vuex-pathify";
import axios from '@/modules/common/axiosFacturacion';
import moment from "moment";
import { util } from '@/plugins/util';

const state = () => ({
    nombreComercial: null,
    razonSocial: null,
    nit: null,
    fecha: new Date(moment()),
    fechaFinal: new Date(moment()),
    origenUrl: null,
    entornoSelected: null,
    ciudadSelected: null,
    paqueteSelected: null,
    paquetes: [],
    facturacionTipos: [],
    facturacionSelected: null,
    documentoSelected: [],
    tipoDocumentos: [],
    importe: 0,
    monedaSelected: null,
    monedas: [],
    cantidadTimbre: null,
    confirmarTimbre: null,
    tiempoSelected: null,
    tiempos: [],
    cantidadActual: null,
    fechaVencimiento: null,
    proveedorSoftwares: [],
    proveedorSoftwareSelected: null,
    ambientes: [],
    ambienteSelected: null,
    tokenDelegado: null,
    vencimientoToken: null,
    entornos: [],
    razonSocialNumero: null,
    medicoNumero: null,
});

const mutations = make.mutations(state);

const getters = {};

const actions = {
    async getDatosInicial({commit}) {
        try {
            const RESPONSE = await axios.get('/facturacion/empresa/create');
            commit('SET_MONEDAS', RESPONSE.data.data.monedas);
            commit('SET_PROVEEDOR_SOFTWARES', RESPONSE.data.data.proovedor_softwares);
            commit('SET_FACTURACION_TIPOS', RESPONSE.data.data.facturacions);
            commit('SET_PAQUETES', RESPONSE.data.data.paquetes);
            commit('SET_AMBIENTES', RESPONSE.data.data.ambientes);
            commit('SET_TIEMPOS', RESPONSE.data.data.tiempos);
            commit('SET_TIPO_DOCUMENTOS', RESPONSE.data.data.siat_tipo_documento_sectors);
            commit('SET_ENTORNOS', RESPONSE.data.data.entornos);

        } catch (error) {
            Object.entries(error.response.data.data).forEach(([, mensajes]) => {
                mensajes.forEach((texto) => util.showNotify(texto, 'error'));
            });
            return false;
        }
    },
    validar({state}) {
        let valido = true;
        if (!state.paqueteSelected) {
            util.showNotify('El campo paquete es requerido', 'warn');
            valido = false;
        }
        if (!state.proveedorSoftwareSelected) {
            util.showNotify('El campo proveedor de software es requerido', 'warn');
            valido = false;
        }
        if (!state.facturacionSelected) {
            util.showNotify('El campo facturacion es requerido', 'warn');
            valido = false;
        }
        if (!state.ambienteSelected) {
            util.showNotify('El campo ambiente es requerido', 'warn');
            valido = false;
        }
        if (!state.tiempoSelected) {
            util.showNotify('El campo tiempos es requerido', 'warn');
            valido = false;
        }
        if (!state.monedaSelected) {
            util.showNotify('El campo moneda es requerido', 'warn');
            valido = false;
        }
        if (!state.fecha) {
            util.showNotify('El campo fecha inicio es requerido', 'warn');
            valido = false;
        }
        if (!state.fechaFinal) {
            util.showNotify('El campo fecha final es requerido', 'warn');
            valido = false;
        }
        if (state.fecha > state.fechaFinal) {
            util.showNotify('El campo fecha final debe ser mayor que la fecha inicio', 'warn');
            valido = false;
        }
        if (!state.entornoSelected) {
            util.showNotify('Debe selecionar un tipo de entorno', 'warn');
            valido = false;
        }
        return valido;
    },
    async storeEmpresa({ state, dispatch, rootState}) {
        try {
            if (await dispatch('validar')) {
                let  lisTokens = rootState.configuracion.theModalListToken.lisTokens;
                const REQUEST = {
                    nombre_comercial: state.nombreComercial,
                    razon_social: state.razonSocial,
                    origen: state.origenUrl,
                    nit: state.nit,
                    ciudad: state.ciudadSelected,
                    paquete_id: state.paqueteSelected.id,
                    proveedor_sofware_id: state.proveedorSoftwareSelected.id,
                    facturacion_id: state.facturacionSelected.id,
                    ambiente_id: state.ambienteSelected.id,
                    importe: state.importe,
                    moneda_id: state.monedaSelected.id,
                    cantidad_timbre: state.cantidadTimbre,
                    confirmar_timbre: state.confirmarTimbre,
                    tiempo_id: state.tiempoSelected.id,
                    cantidad_actual: state.cantidadActual,
                    fecha_inicio: moment(state.fecha).format('YYYY-MM-DD'),
                    fecha_final: moment(state.fechaFinal).format('YYYY-MM-DD'),
                    documento_sectors: state.documentoSelected,
                    token_delegado: state.tokenDelegado,
                    fecha_vencimiento_token: (state.vencimientoToken) ? moment(state.vencimientoToken, 'DD/MM/YYYY').format("YYYY-MM-DD") : '',
                    list_tokens: lisTokens,
                    tipo_entorno: state.entornoSelected.id,
                };
                const {data} = await axios.post('facturacion/empresa/store', REQUEST);
                util.showNotify(data.message, 'success');
                return true;
            }
        } catch (error) {
            const FALLO_VALIDACION = 422;
            if (error.response.status !== FALLO_VALIDACION) {
                util.showNotify(error.response.data.message, 'error');
            } else {
                Object.entries(error.response.data.data).forEach(([, mensajes]) => {
                    mensajes.forEach((texto) => util.showNotify(texto, 'error'));
                });
            }
            return false;
        }
    },
    reset( {commit} ) {
        commit("SET_NOMBRE_COMERCIAL", null);
        commit("SET_RAZON_SOCIAL", null);
        commit("SET_NIT", null);
        commit("SET_FECHA", new Date(moment()));
        commit("SET_FECHA_FINAL", new Date(moment()));
        commit("SET_ORIGEN_URL", null);
        commit("SET_CIUDAD_SELECTED", null);
        commit("SET_PAQUETE_SELECTED", null);
        commit("SET_FACTURACION_SELECTED", null);
        commit("SET_DOCUMENTO_SELECTED", []);
        commit("SET_IMPORTE", 0);
        commit("SET_MONEDA_SELECTED", null);
        commit("SET_CANTIDAD_TIMBRE", null);
        commit("SET_FACTURACION_SELECTED", null);
        commit("SET_CONFIRMAR_TIMBRE", null);
        commit("SET_TIEMPO_SELECTED", null);
        commit("SET_CANTIDAD_ACTUAL", null);
        commit("SET_FECHA_VENCIMIENTO", null);
        commit("SET_AMBIENTE_SELECTED", null);
        commit("SET_TIPO_DOCUMENTOS", []);
        commit("SET_TOKEN_DELEGADO", null);
        commit("SET_VENCIMIENTO_TOKEN", null);
        commit("SET_PROVEEDOR_SOFTWARE_SELECTED", null);
        commit("configuracion/theModalListToken/SET_LIS_TOKENS", [], {root: true});
        commit("SET_ENTORNOS", []);
        commit("SET_ENTORNO_SELECTED", null);
        commit("SET_MEDICO_NUMERO", null);
        commit("SET_RAZON_SOCIAL_NUMERO", null);
    },

};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions,
}