const title = 'Empresas';
const empresaRoutes = [
    {
        path: 'empresa',
        name: 'empresa-index',
        component: () => import('../views/index.vue'),
        meta: {
            title,
        },
    },
    {
        path: 'empresa/create',
        name: 'empresa-create',
        component: () => import('../views/create.vue'),
        meta: {
            title: `${title} | Nuevo`,
        },
    },
    {
        path: 'empresa/:id/edit',
        name: 'empresa-edit',
        component: () => import('../views/edit.vue'),
        meta: {
            title: `${title} | Editar`,
        },
    },
];

export default empresaRoutes;