import paqueteIndex from "./apis/index";
import paqueteStore from "./apis/store";
import paqueteUpdate from "./apis/update";
import paqueteDestroy from "./apis/destroy"; 

export default {
    namespaced: true,
    modules: {
        paqueteIndex,
        paqueteStore,
        paqueteUpdate,
        paqueteDestroy,
    },
}