
const title = 'Configuracion';
const configuracionRoutes = [
    {
        path: 'configuracion',
        name: 'configuracion-index',
        component: () => import('../views/index.vue'),
        meta: {
            title,
        },
    },
    {
        path: 'configuracion/create',
        name: 'configuracion-create',
        component: () => import('../views/create.vue'),
        meta: {
            title: `${title} | Nuevo`,
        },
    },
    {
        path: 'configuracion/:id/edit',
        name: 'configuracion-edit',
        component: () => import('../views/edit.vue'),
        meta: {
            title: `${title} | Nuevo`,
        },
    },
];

export default configuracionRoutes;