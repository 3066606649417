import { make } from "vuex-pathify";
import axios from '@/modules/common/axiosFacturacion';
import { util } from '@/plugins/util';

const state = () => ({
    fields: [
        {
            key: 'editar', label: '', class: 'text-center', thStyle: { width: '3%' },
        },
        {
            key: 'eliminar', label: '', class: 'text-center', thStyle: { width: '3%' },
        },
        {
            key: 'numero', label: 'N°', class: 'text-center', thStyle: { width: '3%' },
        },
        {
            key: 'proveedor_software', label: 'Proveedor de Software', class: 'text-center', thStyle: { width: '20%' },
        },
        {
            key: 'nombre_sistema', label: 'Nombre del sistema', class: 'text-center', thStyle: { width: '15%' },
        },
        {
            key: 'nit', label: 'Nit', class: 'text-center', thStyle: { width: '10%' },
        },
        {
            key: 'pais', label: 'Pais', class: 'text-center', thStyle: { width: '10%' },
        },
        {
            key: 'updated_at', label: 'Ultima Modificacion', class: 'text-center', thStyle: { width: '15%' },
        },                
        
    ],
    tablaConfiguracions: [],
    filter: '',
    porPagina: 100,
    paginaOpciones: [100, 200],
    estado: true,
    paginaActual: 1,
    totalFila: 0,
    isLoading: true,
})

const mutations = make.mutations(state);

const getters = {};

const actions = {
    listarConfiguracion({ state, commit}) {
        state.isLoading = true;
        const param = {
            page: state.paginaActual,
            per_page: state.porPagina,
            estado: state.estado,
            filter_key: state.filter,
        };
        axios.get('facturacion/configuracion', { params: param })
        .then(response => {
            const DATA = response.data.data;
            commit("SET_TABLA_CONFIGURACIONS", DATA.data.configuracions);
            commit("SET_TOTAL_FILA", DATA.data.total);
        })
        .catch( error => {
            Object.entries(error.response.data.data).forEach(([, mensajes]) => {
                mensajes.forEach((texto) => util.showNotify(texto, 'error'));
            });
        }).finally(() => {
            state.isLoading = false;
        })
    },
    reset({ commit }) {
        commit("SET_FILTER", '');
        commit("SET_ESTADO", true);
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions,
}