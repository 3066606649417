import Vue from "vue";
import VueRouter from "vue-router";
import store from '../store';

import loginRoutes from "../modules/login/routes/loginRoutes";
import dashboardRoutes from '../modules/dashboard/routes/dashboardRoutes';
import usuarioRoutes from '../modules/usuarios/routes/usuarioRoutes';
import datoUsuario from '../modules/datoUsuario/routes/usuarioRoutes';
import paqueteRoutes from "../modules/paquetes/routes/paqueteRoutes";
import empresaRoutes from "../modules/empresas/routes/empresaRoutes";
import configuracionRoutes  from "../modules/configuracion/routes/configuracionRoutes";
import clienteRoutes from "../modules/clientes/routes/clienteRoutes";
import facturaracionRoutes from "../modules/facturacion/routes/facturacionRoutes";
import datoEmpresaRoute from "../modules/datosEmpresa/routes/datosEmpresaRoutes";
import sincronizarSiatRoute from "../modules/sincronizarSiat/routes/sincronizarSiatRoutes";

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component:() => import("../views/app/index.vue"),
    redirect: '/dashboard',
    async beforeEnter(to, from, next) {
      await store.dispatch('main/getData', { root: true });
      next();
    },
    children: [
      ...dashboardRoutes,
      ...usuarioRoutes,
      ...paqueteRoutes,
      ...empresaRoutes,
      ...configuracionRoutes,
      ...clienteRoutes,
      ...facturaracionRoutes,
      ...datoEmpresaRoute,
      ...sincronizarSiatRoute,
      ...datoUsuario,
    ],
  },
  ...loginRoutes,
  {
    path: '/facturas',
    component: () => import('../components/consultarFactura.vue'),
  },
  {
    path: '*',
    component: () => import('../views/app/pages/notFound.vue'),
  },
];
  const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
  });

  router.beforeEach((to, from, next) => {
    document.title = to.meta.title ? to.meta.title : 'Gemgloo-Factura';
    const { isAuthenticated } = store.state.auth;
    if (to.name !== 'login' && (isAuthenticated === false)) {
      next({ name: 'login' });
    } else {
      next();
    }
  });
  
  export default router;