import { make } from 'vuex-pathify';
import axios from '@/modules/common/axiosFacturacion';
import { util } from '@/plugins/util';

const state = () => ({
    sucursal: {},
    modalCreateUpdateSucursal: false,
    ciudades: [],
    selectedCity: {
        nombre: "",
        id: '-1'
    }
});

const mutations = make.mutations(state);

const getters = {};

const actions = {
    reset({ commit }) {
        commit('SET_SUCURSAL', null);
        commit('SET_MODAL_CREATE_UPDATE_SUCURSAL', false);
        commit('SET_CIUDADES', []);
        commit('SELECTED_CITY', null)
    },

    async storeUpdateSucursal({ state, dispatch }) {
        try {
            if(await dispatch('validar')){
                const REQUEST = new FormData()
                REQUEST.append('nombre_comercial', state.sucursal.nombre_comercial)
                REQUEST.append('nro_sucursal', state.sucursal.numero_sucursal)
                REQUEST.append('telefono', state.sucursal.telefono)
                REQUEST.append('direccion', state.sucursal.direccion)
                REQUEST.append('ciudad_id', state.selectedCity.id)
                
                let parameter
                if(state.sucursal.id > 0){
                    const params = {
                        'nombre_comercial' : state.sucursal.nombre_comercial,
                        'nro_sucursal' : state.sucursal.numero_sucursal,
                        'telefono' : state.sucursal.telefono,
                        'direccion' : state.sucursal.direccion,
                        'ciudad_id' : state.selectedCity.id,
                    }
                    parameter = await axios.put(`siat/operaciones/sucursal/${state.sucursal.id}`, null, {
                        params : params
                    })
                }else{
                    parameter = await axios.post('siat/operaciones/sucursal', REQUEST)
                }
                util.showNotify(parameter.data.message, 'success');
                dispatch('sincronizacion/sucursal/sucursal/getSucursales', null, { root: true });
            }
        } catch (error) {
            util.showNotify(error.response.data.message, 'error');
            return false;
        }
    },

    async validar({ state }) {
        let valido = true;
        if (!state.sucursal.nombre_comercial) {
            util.showNotify('El campo nombre comercial es requerido', 'warn');
            valido = false;
        }
        if (state.sucursal.numero_sucursal < 0) {
            util.showNotify('El campo numero de sucursal es requerido', 'warn');
            valido = false;
        }
        if (!state.sucursal.telefono) {
            util.showNotify('El campo telefono es requerido', 'warn');
            valido = false;
        }
        if (!state.sucursal.direccion) {
            util.showNotify('El campo direccion es requerido', 'warn');
            valido = false;
        }
        if (!state.selectedCity) {
            util.showNotify('El campo ciudad es requerido', 'warn');
            valido = false;
        }
        return valido;
    },
    
    async getParams({commit}){
        try {
            const { data } = await axios.get(`siat/operaciones/sucursal/create`);            
            commit("SET_CIUDADES", data.data.ciudades);
        } catch (error) {
            util.showNotify(error.response.data.message, 'error');
            return [];
        }
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions,
};